import CustomerGeneralForm from "components/Forms/CustomerGeneralForm";
import { CustomerGeneralFormFieldEnum } from "components/Forms/CustomerGeneralForm/CustomerGeneralFormFieldEnum";
import Header from "components/Header";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import { generatePath, useNavigate } from "react-router-dom";
import { createCustomerStore } from "stores/CreateCustomerStore";

const PageCreateCustomer = () => {
    const navigate = useNavigate();

    const onSubmit = async (data: { [x in CustomerGeneralFormFieldEnum]: string; }) => {
        const newCustomer = await createCustomerStore.createCustomer({
            name: data.name,
            vat: data.vat,
            address: data.address,
            zip: data.zip,
            city: data.city
        })

        navigate(generatePath(routes.pageCustomerDetails, { id: newCustomer.id }), { replace: true });
    }

    return (
        <div>
            <Header
                onBack={() => navigate(-1)}
            />
            <TwoColumnWrapper
                title='Opret kunde'
                leftColumn={
                    <CustomerGeneralForm
                        onSubmit={onSubmit}
                        onCancel={() => navigate(-1)}
                        showActionButtonsByDefault={true}
                    />
                }
            />
        </div>
    )

}

export default PageCreateCustomer;