import { routes } from "components/Routes";
import SettingsWrapper, { HeaderSubMenuItemEnum } from "components/SettingsWrapper";
import { useNavigate } from "react-router-dom";
import UsersTable from 'components/UsersTable';

const PageSettingsUsers = () => {
    const navigate = useNavigate();

    return (
        <SettingsWrapper
            title='Brugere'
            actionButton={{ label: 'Opret bruger', onClick: () => navigate(routes.pageCreateUser) }}
            activeSubMenuItem={HeaderSubMenuItemEnum.USERS}>
            <UsersTable />
        </SettingsWrapper>
    )
}

export default PageSettingsUsers;