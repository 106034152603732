import NotifyRecipientsModal from "components/NotifyRecipientsModal";

interface Props {
    title?: string,
    topRight?: React.ReactNode,
    leftColumn?: React.ReactNode,
    rightColumn?: React.ReactNode
    skipNotifyModal?: boolean
}

const TwoColumnWrapper = (props: Props) => {
    return (
        <>
            <div className='mx-2 md:mx-12 '>
                <div className='
                    mt-6
                    md:mt-12
                    mb-12
                    flex 
                    items-center 
                    place-content-between'>
                    <h1 className='text-2xl'>
                        {props.title}
                    </h1>
                    <div>
                        {props.topRight}
                    </div>
                </div>
                <div className='grid gap-12 grid-cols-1 lg:grid-cols-2'>
                    <div>
                        {props.leftColumn}
                    </div>
                    <div>
                        {props.rightColumn}
                    </div>
                </div>
                {
                    !props.skipNotifyModal &&
                    <NotifyRecipientsModal />
                }

            </div>
        </>
    )
}

export default TwoColumnWrapper;