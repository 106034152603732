import FullCalendar, {
	createDuration,
	DateSelectArg,
	EventApi,
	EventClickArg,
	EventContentArg,
} from "@fullcalendar/react";
import daLocale from "@fullcalendar/core/locales/da";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import interactionPlugin from "@fullcalendar/interaction";
import adaptivePlugin from "@fullcalendar/adaptive";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import EventsListCalendarWrapper, {
	HeaderSubMenuItemEnum,
} from "components/EventsListCalendarWrapper";

import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { eventsStore } from "stores/EventsStore";
import { observer } from "mobx-react-lite";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "components/Routes";
import { Theme, Tooltip, withStyles } from "@material-ui/core";
import { createEventStore } from "stores/CreateEventStore";
import { EventStatusEnum } from "types/EventStatusEnum";
import { SelectMonthEnum } from "types/SelectMonthEnum";

const HtmlTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: "#173445", // office club dark blue
		color: "#fff",
		maxWidth: 360,
		fontSize: theme.typography.pxToRem(12),
	},
	arrow: {
		color: "#173445", // office club dark blue
	},
}))(Tooltip);

const PageCalendar = observer(() => {
	const [isMounted, setIsMounted] = useState(false);
	const navigate = useNavigate();
	const calendarComponentRef = useRef<any>();

	const { year, month } = eventsStore;

	useEffect(() => {
		if (eventsStore.month === SelectMonthEnum.WHOLE_YEAR) {
			eventsStore.setMonth(moment().month().toString());
		}

		const getEvents = async () => {
			await eventsStore.getEvents();
			setIsMounted(true);
		};

		getEvents();
	}, []);

	useEffect(() => {
		const monthFormatted = moment().month(parseInt(month)).format("MM");
		getCalendarApi().gotoDate(`${year}-${monthFormatted}-01`);
	}, [year, month]);

	const getCalendarApi = () => {
		return calendarComponentRef.current.getApi();
	};

	const onEventClicked = (e: EventClickArg) => {
		e.jsEvent.cancelBubble = true;
		e.jsEvent.preventDefault();

		if (!e.event.url) {
			// Public holiday calendar has URL. Do nothing
			const eventId = e.event.id;
			navigate(generatePath(routes.pageEventDetails, { id: eventId }));
		}
	};

	const availableTimeslotClicked = (arg: DateSelectArg) => {
		if (!window.confirm("Opret nyt arrangement?")) return;

		const startTime = moment(arg.start).hour(8).minute(0).toDate();

		createEventStore.setDate(startTime);
		navigate(routes.pageCreateEvent);
	};

	const events = eventsStore.filteredEvents.map((ev) => ({
		id: ev.id,
		title: ev.title,
		customer: ev.customer?.name || "",
		locationColor: ev.location?.color,
		resource: ev.location?.name,
		description: ev.description,
		start: moment(ev.from).format(),
		end: moment(ev.to).format(),
		status: ev.status,
	}));

	const formatTitle = (event: EventApi) => {
		// Events from Lessor have timespan in title.
		// If it is there, simply return the title - Or else append timespan.
		if (event.title.indexOf("kl.") !== -1) {
			return event.title;
		} else {
			return `${event.title} kl. ${moment(event.start).format("H:mm")}`;
		}
	};

	return (
		<EventsListCalendarWrapper
			activeSubMenuItem={HeaderSubMenuItemEnum.CALENDAR}
		>
			<FullCalendar
				ref={calendarComponentRef}
				headerToolbar={{ left: "", center: "", right: "" }}
				schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
				showNonCurrentDates={false}
				plugins={[
					dayGridPlugin,
					googleCalendarPlugin,
					interactionPlugin,
					adaptivePlugin as any,
				]}
				initialView='dayGridMonth'
				locale={daLocale}
				slotMinTime={createDuration(8, "hour")}
				height={900}
				weekNumbers={true}
				weekNumberClassNames={(args) => "text-xs font-medium text-gray-500"}
				fixedWeekCount={false}
				slotLabelClassNames='font-medium text-xs m-auto'
				eventClassNames="rounded-none text-white text-xs"
				eventClick={onEventClicked}
				selectable={true}
				eventDisplay='block'
				selectMirror={true}
				select={availableTimeslotClicked}
				selectMinDistance={10}
				snapDuration='00:1:00'
				eventSources={[
					isMounted &&
						({
							googleCalendarId: "da.danish#holiday@group.v.calendar.google.com",
							googleCalendarApiKey:
								process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY,
						} as any),
					events,
				]}
				eventContent={(args) => {
					const evProps = args.event._def.extendedProps;
					return (
						<HtmlTooltip
							arrow
							placement='top'
							title={
								<>
									<b>{evProps.resource}</b>
									<br />
									{evProps.description}
								</>
							}
						>
							<div
								className='
                                    pl-1 
                                    cursor-pointer
                                '
								style={{
									background: evProps.locationColor || "#000000",
									textDecoration:
										evProps.status === EventStatusEnum.CANCELLED
											? "line-through"
											: undefined,
									opacity:
										evProps.status === EventStatusEnum.CANCELLED ? 0.4 : 1,
								}}
							>
								{formatTitle(args.event)}
								<br />
								{evProps.customer}
							</div>
						</HtmlTooltip>
					);
				}}
			/>
		</EventsListCalendarWrapper>
	);
});

export default PageCalendar;
