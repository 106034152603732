import { YupInterface } from 'types/YupInterface';
import logoImageSrc from './logo.svg';
import css from './style.module.scss';

interface Props {
    title: string
    children: React.ReactNode,
    secondaryAction?: {
        label: string,
        onClick: () => void
    }
}

const NotLoggedInContainer = (props: Props) => {

    const validation = (yup: YupInterface) => {
        return {
            email: yup.string().required('Email er påkrævet'),
            password: yup.string().required('Password er påkrævet'),
        }
    }

    return (
        <div className='w-full h-screen flex'>
            <div className={`hidden lg:block ${css.left}`}>
                <img
                    src={logoImageSrc}
                    alt='Musikteatret Holstebro'
                    className='p-6'
                />
            </div>
            <div className='mx-auto my-auto'>

                <div className='bg-white p-6 min-w-[300px] md:w-[400px] rounded-[20px]'>
                    <h1 className='text-xl mb-8'>
                        {props.title}
                    </h1>
                    <div>
                        {props.children}
                    </div>

                </div>
                {
                    props.secondaryAction &&
                    <div className='text-sm text-gray-600 mt-12 text-center'>
                        <button
                            onClick={props.secondaryAction.onClick}
                            type='button'
                            className='underline'>
                            {props.secondaryAction.label}
                        </button>
                    </div>
                }

            </div>
        </div>
    )
}

export default NotLoggedInContainer;