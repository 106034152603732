import { faBell, faCalendarAlt, faDoorOpen, faWrench } from "@fortawesome/free-solid-svg-icons";
import { routes } from "components/Routes";
import React from "react";
import { useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { UserRoleEnum } from "types/UserRoleEnum";
import HeaderMenuItem from "./HeaderMenuItem";
import logoImageSrc from './logo.svg';

export enum ActiveHeaderEnum {
    EVENTS,
    MESSAGES,
    SETTINGS
}

interface Props {
    activeMenu?: ActiveHeaderEnum,
    subItems?: React.ReactNode,
    onBack?: () => void
}

const Header = (props: Props) => {

    const navigate = useNavigate();

    return (
        <div className='print:hidden'>
            <div className='
                bg-dark-red
                h-[90px]
                rounded-bl-[20px]
                rounded-br-[20px]
                flex
                items-center
                drop-shadow
            '>
                <img
                    onClick={() => navigate(routes.pageFront)}
                    className='
                        cursor-pointer
                        ml-8
                        hidden
                        sm:block
                    '
                    src={logoImageSrc}
                    alt='Musikteatret Holstebro'
                    style={{ height: '25px' }}
                />
                <div className='flex-1 text-center sm:text-right sm:mr-8'>
                    <ul>
                        <HeaderMenuItem
                            onClick={() => navigate(routes.pageFront)}
                            icon={faCalendarAlt}
                            label='Arrangementer'
                            isActive={props.activeMenu === ActiveHeaderEnum.EVENTS}
                        />
                        <HeaderMenuItem
                            onClick={() => navigate(routes.pageUserNotifications)}
                            icon={faBell}
                            label='Underretninger'
                            isActive={props.activeMenu === ActiveHeaderEnum.MESSAGES}
                        />
                        <HeaderMenuItem
                            onClick={() => navigate(authStore.user?.role === UserRoleEnum.ADMIN ? routes.pageSettingsCustomers : routes.pageSettingsChangePassword)}
                            icon={faWrench}
                            label='Indstillinger'
                            isActive={props.activeMenu === ActiveHeaderEnum.SETTINGS}
                        />
                        <HeaderMenuItem
                            onClick={() => authStore.logout()}
                            icon={faDoorOpen}
                            label='Log ud'
                        />
                    </ul>
                </div>
            </div>
            {
                (props.subItems || props.onBack) &&
                <div className='
                mx-auto
                bg-light-red
                mx-6
                sm:mx-12
                h-[60px]
                rounded-bl-[20px]
                rounded-br-[20px]
                flex
                items-center
                justify-center
                sm:justify-end
            '>
                    {
                        props.onBack &&
                        <div className='flex-1 '>
                            <button
                                onClick={props.onBack}
                                type='button'
                                className='ml-2 text-white text-2xl px-4'>
                                &larr;
                            </button>
                        </div>
                    }

                    <div className='sm:mr-8 text-sm text-white'>
                        <ul>
                            {props.subItems}
                        </ul>
                    </div>
                </div>
            }

        </div>
    )
}

export default Header;