import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { redirectStore } from "stores/RedirectStore";

const LoggedInContainer = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = authStore.isLoggedIn;

    useEffect(() => {
        if (!isLoggedIn) {
            redirectStore.setLocation(location.pathname);
            navigate(routes.pageLogin);
        }
    }, [isLoggedIn, navigate])

    const user = authStore.user;

    const gotoChangePassword = () => {
        navigate(routes.pageSettingsChangePassword);
    }

    return (
        <div className='
            mx-auto
            max-w-[1440px]
        '>
            {
                user?.setNewPassword &&
                <div className='w-full bg-black text-white text-center py-2 px-2'>
                    Hej nye bruger 👋 Skift venligst dit midlertidige password til et du kan huske&nbsp;
                    <button 
                        onClick={() => gotoChangePassword()}
                        type='button' 
                        className='underline transparent'>
                        ved at klikke her
                    </button>
                </div>
            }
            <Outlet />
        </div>
    )

})

export default LoggedInContainer;