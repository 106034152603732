import { action, makeObservable, observable } from "mobx";
import { ContactListInterface } from "types/ContactListInterface";
import { BaseStore } from "./BaseStore";

class ContactDetailsStore extends BaseStore {

    contact: ContactListInterface | null = null;

    constructor() {
        super();
        makeObservable(this, {
            contact: observable,

            resetStore: action,
            getContact: action,
            updateContact: action
        })
    }

    resetStore = () => {
        this.contact = null;
    }

    getContact = (customerId: string, contactId: string) => {
        return this.get(`customers/${customerId}/contacts/${contactId}`, 'contact');
    }

    updateContact = (customerId: string, contact: ContactListInterface) => {
        return this.put(`customers/${customerId}/contacts/${contact.id}`, contact, 'contact');
    }

}

export const contactDetailsStore = new ContactDetailsStore();