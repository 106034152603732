import Form from 'components/Forms/Form';
import FormFieldWatcher from 'components/Forms/FormFieldWatcher';
import Input from 'components/Forms/Input';
import { routes } from 'components/Routes';
import Table from 'components/Table';
import Cell from 'components/Table/Cell';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { customersStore } from 'stores/CustomersStore';
import { CustomerListInterface } from 'types/CustomerListInterface';

const CustomersTable = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        customersStore.getCustomers();

        return () => {
            customersStore.resetStore();
        }
    }, [])

    const onSearchQueryChanged = (value: string) => {
        customersStore.setSearchQuery(value);
    }

    const customers = customersStore.filteredCustomers;

    return (
        <>
            <div className='max-w-[250px] mb-4'>
                <Form
                    onSubmit={() => null}>
                    <FormFieldWatcher field='searchValue' onChange={onSearchQueryChanged} />
                    <Input
                        name='searchValue'
                        className='w-full'
                        placeholder='Søg på kunde'
                    />
                </Form>
            </div>
            <Table
                data={customers}
                defaultSortProperty='name'
                defaultSortDirection='asc'
                onRowClicked={(c: CustomerListInterface) => navigate(generatePath(routes.pageCustomerDetails, { id: c.id }))}
                head={[
                    {
                        label: 'Navn',
                        sortProperty: 'name'
                    },
                    {
                        label: 'CVR',
                        sortProperty: 'vat'
                    },
                    {
                        label: 'Adresse',
                        sortProperty: 'address'
                    },
                    {
                        label: 'By',
                        sortProperty: 'city'
                    },

                ]}
                renderRow={(customer: CustomerListInterface) => {
                    return (
                        <>
                            <Cell>{customer.name}</Cell>
                            <Cell>{customer.vat}</Cell>
                            <Cell>{customer.address}</Cell>
                            <Cell>{customer.city} {customer.zip ? `(${customer.zip})` : ''}</Cell>
                        </>
                    )
                }}
            />
        </>
    )
})

export default CustomersTable;