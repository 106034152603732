import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import NotLoggedInContainer from 'components/NotLoggedInContainer';
import { routes } from 'components/Routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { YupInterface } from 'types/YupInterface';

const PageForgotPassword = () => {
    const [isComplete, setIsComplete] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        try {
            await authStore.resetPassword(data.email);
            setIsComplete(true);
        }
        catch (error) {
            toast('Der opstod en fejl', { type: 'error' });
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            email: yup.string().email('Indtast en gyldig email').required('Email er påkrævet')
        }
    }

    return (
        <NotLoggedInContainer
            secondaryAction={{
                label: 'Tilbage til forside',
                onClick: () => navigate(routes.pageLogin)
            }}
            title='Gendan dit password'>
            {
                !isComplete &&
                <Form onSubmit={onSubmit} validation={validation}>
                    <Input
                        label='Email'
                        name='email'
                    />
                    <div className='text-right pt-4'>
                        <Button
                            type='submit'
                            appearance='primary'
                            label='Nulstil password'
                        />
                    </div>
                </Form>
            }

            {
                isComplete &&
                <p>
                    Vi har sendt dig en email med yderligere information 😊
                </p>
            }

        </NotLoggedInContainer>
    )
}

export default PageForgotPassword;