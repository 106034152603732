import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import SelectDateTime from "components/Forms/SelectDateTime";
import Header from "components/Header";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import WhiteBox from "components/WhiteBox";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { eventDetailsStore } from "stores/EventDetailsStore";
import { resourceStore } from 'stores/ResourceStore';
import { ResourceAvailabilityInterface } from "types/ResourceAvailabilityInterface";

const PageDuplicateEvent = observer(() => {
    const [dateCount, setDateCount] = useState(1);
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            eventDetailsStore.getEvent(id);
            eventDetailsStore.getSections(id);
        }
    }, [id])

    useEffect(() => {
        return () => {
            eventDetailsStore.resetStore();
        }
    }, [])

    const renderDates = () => {
        const items: Array<ReactNode> = [];

        for (var i = 0; i < dateCount; i++) {
            items.push(
                <div className='pb-3' key={i}>
                    <SelectDateTime
                        name={`${i}`}
                        label={`Dato ${i + 1}`}
                        className='w-full'
                        format="date"
                    />
                </div>
            )
        }

        return items;
    }

    const onSubmit = async (data: any) => {
        const event = eventDetailsStore.event;
        if (!event) return;

        const dates: Array<{ from: string, to: string }> = [];

        const eventTimeFrom = moment(event.from).format('HH:mm');
        const eventDuration = moment.duration(moment(event.to).diff(moment(event.from)));

        Object.keys(data).forEach((key) => {
            if (data[key]) {
                const dateFrom = moment(data[key]).format('DD/MM/YYYY')
                const momentFrom = moment(`${dateFrom} ${eventTimeFrom}`, 'DD/MM/YYYY HH:mm');
                const momentTo = momentFrom.clone().add(eventDuration.asMinutes(), 'minutes');

                dates.push({
                    from: momentFrom.toISOString(),
                    to: momentTo.toISOString()
                })
            }
        })

        if (dates.length === 0) {
            toast('Indtast en eller flere datoer', { type: 'error' });
            return;
        }

        const availability = await resourceStore.getAvailability(event.location!.id, dates);
        const busyDates = _.filter(availability, (a) => a.conflictEvent) as Array<ResourceAvailabilityInterface>;
        if (busyDates.length > 0) {
            alert('Kan ikke kopiere! Arrangementets lokale er optaget på følgende datoer i samme tidsrum:\n\n' + busyDates.map((b) => moment(b.from).format('DD/MM/YYYY')).join(','))
            return;
        }

        const duplicatedEvents = await eventDetailsStore.duplicateEvent(event.id, dates.map((d) => d.from)) as Array<any>;
        
        duplicatedEvents.forEach((e) => {
            eventDetailsStore.sendToLessor(e);
        })

        navigate(routes.pageFront);
        toast(`Arrangementet er blevet kopieret til ${duplicatedEvents.length} ny(e) dato(er)`, { type: 'success' });

    }

    const event = eventDetailsStore.event;
    if (!event) return null;

    return (
        <div>
            <Header
                onBack={() => navigate(-1)}
            />
            <TwoColumnWrapper
                title={`Kopier "${event.title}"`}
                leftColumn={
                    <WhiteBox className='mb-4 p-6'>
                        <Form onSubmit={onSubmit}>
                            <div className='grid gap-8 grid-cols-2 mt-6'>
                                <div className='pb-3'>
                                    {renderDates()}
                                    <button
                                        type='button'
                                        onClick={() => setDateCount(dateCount + 1)}
                                        className='text-xs underline'>Tilføj ekstra dato</button>
                                </div>
                            </div>
                            <div className='text-right'>
                                <Button
                                    onClick={() => navigate(-1)}
                                    type='button'
                                    appearance='secondary'
                                    label='Fortryd'
                                    className='mr-4'
                                />

                                <Button
                                    type='submit'
                                    label='Kopier arrangement'
                                    appearance='primary'
                                />
                            </div>
                        </Form>
                    </WhiteBox>
                }
            />
        </div>
    )
})

export default PageDuplicateEvent;