import * as React from 'react';
import { uploadFile } from 'helpers/FileHelper';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'helpers/ErrorMessage';

interface Props {
    onSubmit: Function,
    className?: string,
    customUpload?: boolean,
    allowedFileTypes?: Array<string>
    isDisabled?: boolean,
    maxFileSize?: number
}
class UploadFile extends React.Component<Props> {

    hiddenUploadFieldRef: React.RefObject<any>;

    allowedFileTypes = [
        'png',
        'jpg',
        'pdf',
        'docx',
        'txt'
    ]

    constructor(props: Props) {
        super(props);

        this.hiddenUploadFieldRef = React.createRef();
    }

    selectFile = () => {
        const { isDisabled } = this.props;
        if (isDisabled) return;

        this.hiddenUploadFieldRef.current.click();
    }

    uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        var { onSubmit, customUpload, maxFileSize } = this.props;

        if (e.target.value !== "") {
            var file = e.target.files![0];

            if (customUpload) {
                onSubmit(file);
                return;
            }

            try {
                const doc = await uploadFile(file, maxFileSize);
                onSubmit(doc);
            }
            catch (error) {
                toast(getErrorMessage(error), { type: 'error' });
            }
        }
    }

    render() {
        const { className, children, allowedFileTypes } = this.props;

        var fileTypes = allowedFileTypes ?? this.allowedFileTypes;
        const acceptFiles = fileTypes.map(type => "." + type).toString();

        const hideStyle = {
            height: '0',
            width: '0',
            position: "absolute",
            top: "-9999px",
            overflow: 'hidden'
        } as React.CSSProperties

        return (
            <span>
                <input onChange={this.uploadFile} style={hideStyle} ref={this.hiddenUploadFieldRef} type="file" accept={acceptFiles} />
                <span className={className} onClick={this.selectFile}>
                    {children}
                </span>
            </span>
        );
    }

}

export default UploadFile;