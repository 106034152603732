import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import NotLoggedInContainer from 'components/NotLoggedInContainer';
import { routes } from 'components/Routes';
import { useNavigate } from 'react-router-dom';
import { authStore } from 'stores/AuthStore';
import { YupInterface } from 'types/YupInterface';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { redirectStore } from 'stores/RedirectStore';

const PageLogin = observer(() => {

    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        try {
            await authStore.authenticate(data.email, data.password);
            if (redirectStore.redirectIsActive) {
                const location = redirectStore.location!;
                redirectStore.resetStore();

                navigate(location, { state: routes.pageLogin});
            }
            else {
                navigate(routes.pageFront);
            }

        }
        catch (error) {
            toast('Forkert email eller password', { type: 'error' });
        }

    }

    const validation = (yup: YupInterface) => {
        return {
            email: yup.string().email('Indtast en gyldig email').required('Email er påkrævet'),
            password: yup.string().required('Password er påkrævet'),
        }
    }

    return (
        <NotLoggedInContainer
            secondaryAction={{
                label: 'Glemt dit password?',
                onClick: () => navigate(routes.pageForgotPassword)
            }}
            title='Log ind'>
            <Form onSubmit={onSubmit} validation={validation}>
                <Input
                    label='Email'
                    name='email'
                />

                <Input
                    label='Password'
                    type='password'
                    name='password'
                />

                <div className='text-right pt-4'>
                    <Button
                        type='submit'
                        appearance='primary'
                        label='Log ind'
                    />
                </div>
            </Form>
        </NotLoggedInContainer>
    )
})

export default PageLogin;