import { action, makeObservable, observable } from 'mobx';
import { BaseStore } from './BaseStore';
import request from "helpers/Request";
import { CustomerListInterface } from 'types/CustomerListInterface';

export class CustomerSearchStore extends BaseStore {

    customers: Array<CustomerListInterface> = [];
    
    constructor() {
        super();
        makeObservable(this,
            {
                customers: observable,

                resetStore: action,
                getCustomersByQuery: action,
            }
        );
    }

    resetStore = () => {
        this.customers = [];
    }

    getCustomersByQuery = (query: string): Promise<Array<CustomerListInterface>> => {
        return new Promise(async (resolve, reject) => {
            const res = await request.get(`customers?name=${query}&pageNumber=1&pageSize=99`);
            if (res.data && res.data.items) {
                this.customers = res.data.items;
                resolve(res.data.items);
            }
            else reject();
        })
    }

}

export const customerSearchStore = new CustomerSearchStore();