import { action, makeObservable, observable } from "mobx";
import { ResourceListInterface } from "types/ResourceListInterface";
import { BaseStore } from "./BaseStore";

class ResourceDetailsStore extends BaseStore {

    resource: ResourceListInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            resource: observable,

            resetStore: action,
            getResource: action,
            updateResource: action,
            deleteResource: action,

        })
    }

    resetStore = () => {
        this.resource = null;
    }

    getResource = (id: string) => {
        return this.get(`resources/${id}`, 'resource');
    }

    updateResource = (resource: ResourceListInterface) => {
        return this.put(`resources/${resource.id}`, resource, 'resource');
    }

    deleteResource = (id: string) => {
        return this.delete(`resources/${id}`, id, this.resource);
    }

}

export const resourceDetailsstore = new ResourceDetailsStore();