import { BaseStore } from './BaseStore';
import Axios from 'axios';
import request from 'helpers/Request';
import FileInterface from 'types/FileInterface';
import FileCreateInterface from 'types/FileCreateInterface';

export class FileStore extends BaseStore {

    createFile = (opts: FileCreateInterface): Promise<{presignedUrl: string, expiresAt: string, file: FileInterface}> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`files`, opts);
                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    uploadFileToAzureBlob = (presignedUrl: string, file: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const axiosRawRequest = Axios.create({
                    baseURL: process.env.REACT_APP_API_BASEURL,
                    headers: {
                        'cache-control': 'no-cache'
                    }
                });

                const res = await axiosRawRequest.put(presignedUrl, file as Blob, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob'
                    }
                });

                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    deleteFile = (id: string) => {
        return this.delete(`files`, id, []);
    }

    verifyFile = (fileId: string) => {
        return new Promise(async (resolve, reject) => {
            const file = await request.post(`files/${fileId}/verify`);
            resolve(file.data);
        })
    }
}

export const fileStore = new FileStore();