import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import Textarea from "../Textarea";
import { CustomerGeneralFormFieldEnum } from "./CustomerGeneralFormFieldEnum";

interface Props {
    defaultValues?: { [x in CustomerGeneralFormFieldEnum]?: any },
    onSubmit: (data: { [x in CustomerGeneralFormFieldEnum]: string; }) => void,
    showActionButtonsByDefault?: boolean,
    onCancel?: () => void
}

const CustomerGeneralForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [CustomerGeneralFormFieldEnum.NAME]: yup.string().required('Påkrævet')
        }
    }

    return (
        <WhiteBox className='mb-4 p-6'>
            <div className='flex items-center place-content-between'>
                <h1 className='text-xl'>
                    Kundedetaljer
                </h1>
            </div>
            <Form
                onSubmit={props.onSubmit}
                formOptions={{ defaultValues: props.defaultValues }}
                validation={validation}
                onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
            >
                <div className='grid gap-8 grid-cols-2 mt-6'>
                    <div>
                        <Input name={CustomerGeneralFormFieldEnum.NAME} label='Navn' />
                        <Input name={CustomerGeneralFormFieldEnum.VAT} label='CVR nummer' />
                        <Input name={CustomerGeneralFormFieldEnum.ADDRESS} label='Adresse' />
                        <Input name={CustomerGeneralFormFieldEnum.ZIP} label='Postnummer' />
                        <Input name={CustomerGeneralFormFieldEnum.CITY} label='By' />
                        <Input name={CustomerGeneralFormFieldEnum.EAN} label='EAN-nummer' />
                    </div>
                    <div>
                    <Input name={CustomerGeneralFormFieldEnum.EMAIL} label='Email' />
                    <Input name={CustomerGeneralFormFieldEnum.PHONE} label='Telefon' />
                    <Input name={CustomerGeneralFormFieldEnum.WEBSITE} label='Hjemmeside' />
                    <Textarea name={CustomerGeneralFormFieldEnum.NOTE} label='Note' />
                    </div>
                </div>
                {
                    (props.showActionButtonsByDefault || isFormDirty) &&
                    <div className='text-right'>
                        {
                            props.onCancel &&
                            <Button
                                onClick={() => props.onCancel!()}
                                type='button'
                                appearance='secondary'
                                label='Fortryd'
                                className='mr-4'
                            />
                        }

                        <Button
                            type='submit'
                            label='Gem'
                            appearance='primary'
                        />
                    </div>
                }

            </Form>
        </WhiteBox>
    )

}

export default CustomerGeneralForm;