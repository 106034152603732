import EventDetailsHeader from 'components/EventDetailsHeader';
import TwoColumnWrapper from 'components/TwoColumnWrapper';
import WhiteBox from 'components/WhiteBox';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { eventDetailsStore } from 'stores/EventDetailsStore';

const PageEventDetailsLog = observer(() => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
            if (id) {
                eventDetailsStore.getEvent(id);
                eventDetailsStore.getSections(id);
            }

            return () => {
                eventDetailsStore.resetStore();
            }
    }, [id])

    const event = eventDetailsStore.event;
    if (!event) return null;

    const sections = eventDetailsStore.sections;

    return (
        <div>
            <EventDetailsHeader eventId={id!} />
            <TwoColumnWrapper title="Log" leftColumn={
                <div>
                    <WhiteBox className='mb-4 p-6'>
                        <h1 className='text-lg font-semibold w-full pr-12'>Arrangement</h1>
                        <div className='grid gap-2 mt-4'>
                            <div className='flex justify-between'>
                                <div>Oprettet af</div>
                                <div>{event.createdBy?.name} d. {moment(event.createdOn).format("DD/MM/YYYY HH:mm")}</div>
                            </div>

                            {
                                event.modifiedBy && (
                                    <div className='flex justify-between'>
                                        <div>Sidst redigeret af</div>
                                        <div>{event.modifiedBy?.name} d. {moment(event.modifiedOn).format("DD/MM/YYYY HH:mm")}</div>
                                    </div>
                                )
                            }

                        </div>
                    </WhiteBox>
                    {
                        _.orderBy(sections, s => s.order).map((section) => (
                            <WhiteBox className='mb-4 p-6' key={section.id}>
                                <h1 className='text-lg font-semibold w-full pr-12'>{section.title}</h1>
                                <div className='grid gap-2 mt-4'>
                                    <div className='flex justify-between'>
                                        <div>Oprettet af</div>
                                        <div>{section.createdBy?.name} d. {moment(section.createdOn).format("DD/MM/YYYY HH:mm")}</div>
                                    </div>
                                    {
                                        section.modifiedBy && (
                                            <div className='flex justify-between'>
                                                <div>Sidst redigeret af</div>
                                                <div>{section.modifiedBy?.name} d. {moment(section.modifiedOn).format("DD/MM/YYYY HH:mm")}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </WhiteBox>
                        ))
                    }
                </div>
            }
            />
        </div>
    )
})

export default PageEventDetailsLog;