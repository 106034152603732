import EventDetailsHeader from 'components/EventDetailsHeader';
import Button from "components/Forms/Button";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import WhiteBox from "components/WhiteBox";
import _ from 'lodash';
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { eventDetailsNotificationsStore } from "stores/EventDetailsNotificationsStore";

const PageEventDetailsNotifications = observer(() => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const getNotifications = async () => {
            if (id) {
                await eventDetailsNotificationsStore.getNotifications(id);
            }
            setIsLoaded(true);
        }

        getNotifications();

        return () => {
            eventDetailsNotificationsStore.resetStore();
        }

    }, [id])

    const notifications = eventDetailsNotificationsStore.notifications?.items || [];

    return (
        <div>
            <EventDetailsHeader eventId={id!} />
            {
                isLoaded &&
                <TwoColumnWrapper
                    title='Arrangementsunderretninger'
                    leftColumn={
                        <div>
                            {
                                notifications.map((notification, index) => {
                                    const notificationSent = moment(notification.createdOn).format('DD/MM/YYYY HH:mm')
                                    const notifiedUsers = _.filter(notification.users, (u) => u.notifyOnEmail || u.notifyOnPhone)

                                    return (
                                        <WhiteBox
                                            key={index}
                                            className='mb-4 p-6'>
                                            <h1 className='text-md font-semibold w-full pr-12'>
                                                {notificationSent}
                                            </h1>
                                            <div className='my-4 pl-4' style={{ borderLeft: '2px solid #589CCD' }}>
                                                {notification.message}
                                            </div>
                                            <div className='text-sm'>
                                                {
                                                    notification.sections.length > 0 &&
                                                    <>
                                                        <span className='font-semibold'>Sektion: </span>{notification.sections[0].title} <br />
                                                    </>
                                                }
                                                {
                                                    notifiedUsers.length > 0 &&
                                                    <>
                                                        <span className='font-semibold'>Modtagere: </span>
                                                        {
                                                            notifiedUsers.map((user) => `
                                                                ${user.user.name} 
                                                                (${[user.notifyOnPhone && 'SMS', user.notifyOnEmail && 'Email'].filter(x => x).join(', ')})
                                                            `).join(', ')
                                                        }
                                                        <br />
                                                    </>
                                                }

                                                {
                                                    notifiedUsers.length === 0 &&
                                                    <>
                                                        <span className='font-semibold'>Ingen modtagere</span>
                                                    </>
                                                }

                                            </div>
                                        </WhiteBox>
                                    )
                                })
                            }

                            {
                                notifications.length === 0 &&
                                <div>
                                    Der er endnu ingen underretninger på dette arrangement 🙂
                                </div>
                            }

                            {
                                eventDetailsNotificationsStore.notifications?.pagination.hasNextPage &&
                                <div>
                                    <Button
                                        appearance='primary'
                                        type='button'
                                        label='Indlæs flere'
                                        onClick={() => eventDetailsNotificationsStore.getNotifications(id!)}
                                    />
                                </div>
                            }

                        </div>
                    }
                />
            }
        </div>
    )

})

export default PageEventDetailsNotifications;