import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import SelectUserRole from "../SelectUserRole";
import { UserGeneralFormFieldEnum } from "./UserGeneralFormFieldEnum";

interface Props {
    defaultValues?: { [x in UserGeneralFormFieldEnum]?: any },
    onSubmit: (data: { [x in UserGeneralFormFieldEnum]: string; }) => void,
    showActionButtonsByDefault?: boolean,
    onCancel?: () => void
}

const UserGeneralForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [UserGeneralFormFieldEnum.NAME]: yup.string().required('Påkrævet'),
            [UserGeneralFormFieldEnum.EMAIL]: yup.string().required('Påkrævet'),
            [UserGeneralFormFieldEnum.PHONE]: yup.string().required('Påkrævet'),
            [UserGeneralFormFieldEnum.TITLE]: yup.string().required('Påkrævet'),
            [UserGeneralFormFieldEnum.ROLE]: yup.string().required('Påkrævet'),
        }
    }

    return (
        <WhiteBox className='mb-4 p-6'>
            <div className='flex items-center place-content-between'>
                <h1 className='text-xl'>
                    Generelt
                </h1>
            </div>
            <Form
                onSubmit={props.onSubmit}
                formOptions={{ defaultValues: props.defaultValues }}
                validation={validation}
                onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
            >
                <div className='grid gap-8 grid-cols-2 mt-6'>
                    <div>
                        <Input name={UserGeneralFormFieldEnum.NAME} label='Navn' />
                        <Input name={UserGeneralFormFieldEnum.TITLE} label='Titel' />
                        <SelectUserRole
                            placeholder='Vælg rettighed'
                            name={UserGeneralFormFieldEnum.ROLE}
                        />
                    </div>
                    <div>
                        <Input name={UserGeneralFormFieldEnum.EMAIL} label='Email' />
                        <Input name={UserGeneralFormFieldEnum.PHONE} label='Mobilnummer' />
                    </div>
                </div>
                {
                    (props.showActionButtonsByDefault || isFormDirty) &&
                    <div className='text-right'>
                        {
                            props.onCancel &&
                            <Button
                                onClick={() => props.onCancel!()}
                                type='button'
                                appearance='secondary'
                                label='Fortryd'
                                className='mr-4'
                            />
                        }

                        <Button
                            type='submit'
                            label='Gem'
                            appearance='primary'
                        />
                    </div>
                }

            </Form>
        </WhiteBox>
    )

}

export default UserGeneralForm;