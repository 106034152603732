import Header from 'components/Header';
import HeaderSubMenuItem from 'components/Header/HeaderSubMenuItem';
import { routes } from 'components/Routes';
import { generatePath, useNavigate } from 'react-router-dom';

interface Props {
    eventId: string;
}

const EventDetailsHeader = (props: Props) => {
    const navigate = useNavigate();

    return (
        <Header
                onBack={() => navigate(-1)}
                subItems={
                    <>
                        <HeaderSubMenuItem
                            onClick={() => navigate(generatePath(routes.pageEventDetails, { id: props.eventId }))}
                            label='Detaljer'
                            isActive={window.location.pathname === generatePath(routes.pageEventDetails, { id: props.eventId })}
                        />

                        <HeaderSubMenuItem
                            onClick={() => navigate(generatePath(routes.pageEventDetailsNotifications, { id: props.eventId }))}
                            label='Underretninger'
                            isActive={window.location.pathname === generatePath(routes.pageEventDetailsNotifications, { id: props.eventId })}
                        />

                        <HeaderSubMenuItem
                            onClick={() => navigate(generatePath(routes.pageEventDetailsLog, { id: props.eventId }))}
                            label='Log'
                            isActive={window.location.pathname === generatePath(routes.pageEventDetailsLog, { id: props.eventId })}
                        />
                    </>
                }
            />
    )
}

export default EventDetailsHeader;