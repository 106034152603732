import { action, makeObservable, observable } from "mobx";
import { ContactListInterface } from "types/ContactListInterface";
import { CreateContactInterface } from "types/CreateContactInterface";
import { BaseStore } from "./BaseStore";

class CreateContactStore extends BaseStore {

    contact: ContactListInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            contact: observable,
            resetStore: action,
            createContact: action
        })
    }

    resetStore = () => {
        this.contact = null;
    }

    createContact = (customerId: string, contact: CreateContactInterface): Promise<ContactListInterface> => {
        return this.post(`customers/${customerId}/contacts`, contact, 'contact', true);
    }

}

export const createContactStore = new CreateContactStore();