import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useFormContext } from "react-hook-form";

interface Props {
    label: string,
    onClick?: () => void,
    className?: string,
    type: 'button' | 'submit' | 'reset',
    appearance: 'primary' | 'secondary',
    disabled?: boolean,
    icon?: IconDefinition
}

const Button = (props: Props) => {
    const ctx = useFormContext();
    const isSubmitting = ctx?.formState?.isSubmitting || false;

    const primaryClass = 'text-white rounded bg-light-blue py-2 px-7 text-sm ';
    const secondaryClass = 'text-black rounded bg-[#DADADA] py-2 px-4 text-sm ';

    return (
        <button
            disabled={props.disabled || (props.type === 'submit' && isSubmitting)}
            type={props.type}
            onClick={props.onClick}
            className={`
                ${props.appearance === 'primary' ? primaryClass : ''}
                ${props.appearance === 'secondary' ? secondaryClass : ''}
                ${props.className || ''}
            `}>
            {props.label}
            {
                props.icon &&
                <FontAwesomeIcon icon={props.icon} className={props.label ? 'ml-2' : ''} />
            }

        </button>
    )

}

export default Button;

