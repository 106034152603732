import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { customerDetailsStore } from "stores/CustomerDetailsStore"
import SelectAsync from "../SelectAsync"
import _ from 'lodash';
import { ContactListInterface } from "types/ContactListInterface"
import ContactDetailsModal from "./ContactDetailsModal"
import CreateContactModal from "./CreateContactModal"
import { OptionsOrGroups } from "react-select"

interface Props {
    name: string,
    selectedCustomerName: string
}

const SelectContactPerson = observer((props: Props) => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [newContactName, setNewContactName] = useState('');
    const [contactId, setContactId] = useState<null | string>();
    const [customerId, setCustomerId] = useState<null | string>();

    const { watch, setValue } = useFormContext();

    const [customer] = watch([props.selectedCustomerName]);
    const name = props.name;

    useEffect(() => {
        if (customer && customer.id === customerId) return;

        if (customer) {
            setCustomerId(customer.id)
            customerDetailsStore.getContacts(customer.id);
        }
        else {
            // If customer is cleared, reset contact as well
            setValue(name, null);
        }

        return () => {
            customerDetailsStore.resetStore();
        }
    }, [customer, customerId, name, setValue])

    const value = watch([props.name]);
    useEffect(() => {
        if (value[0]?.id) {
            setContactId(value[0].id);
        }
        else {
            setContactId(null);
        }
    }, [value])

    const onShowCreateModal = (contactName: string) => {
        setNewContactName(contactName);
        setShowCreateModal(true);
    }

    const afterSubmitNewContact = (contact: ContactListInterface) => {
        setValue(props.name, {
            id: contact.id,
            name: contact.name
        })

        onCloseNewContact();
    }

    const onCloseNewContact = () => {
        setNewContactName('');
        setShowCreateModal(false);
    }

    const onCloseEditModal = (contact: ContactListInterface) => {
        setValue(props.name, {
            id: contact.id,
            name: contact.name
        })

        setShowEditModal(false);
    }

    const loadOptions = async (value: string, callback: (options: OptionsOrGroups<any, any>) => void) => {
        if (!value) {
            customerDetailsStore.resetStore();
            callback([]);
        }

        if (value.length > 2) {
            const results = await customerDetailsStore.getContacts(customer.id) as ContactListInterface;
            if (results) {
                const filtered = _.filter(results, (r: any) => r.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
                callback(filtered.map((entity: any) => ({
                    id: entity.id,
                    name: entity.name
                })))
            }
        }
        callback([]);
    }

    return (
        <>
            <SelectAsync
                name={props.name}
                label={
                    <span>
                        Kontaktperson
                        {contactId
                            ?
                            <button
                                onClick={() => setShowEditModal(true)}
                                type='button'
                                className='underline ml-1'>
                                [vis detaljer]
                            </button>
                            : undefined}
                    </span>}
                loadOptions={loadOptions}
                placeholder='Begynd at skrive...'
                onCreate={(value) => onShowCreateModal(value)}
            />
            {
                (showCreateModal && customer) &&
                <CreateContactModal
                    onCancel={onCloseNewContact}
                    afterSubmit={afterSubmitNewContact}
                    newContactName={newContactName}
                    customerId={customer.id!}
                />
            }

            {
                (showEditModal && customer) &&
                <ContactDetailsModal
                    contactId={contactId}
                    customerId={customer.id!}
                    onClose={onCloseEditModal}
                />
            }
        </>
    )
})

export default SelectContactPerson