import request from "helpers/Request";
import { action, makeObservable, observable } from "mobx";
import { ResourceAvailabilityInterface } from "types/ResourceAvailabilityInterface";
import { ResourceListInterface } from "types/ResourceListInterface";
import { BaseStore } from "./BaseStore";

class ResourceStore extends BaseStore {

    resources: Array<ResourceListInterface> = [];

    constructor() {
        super();
        makeObservable(this, {
            resources: observable,
            resetStore: action,
            getResources: action
        });
    }

    resetStore = () => {
        this.resources = [];
    }

    getResources = () => {
        return this.get(`resources`, 'resources');
    }

    getAvailability = (resourceId: string, dates: Array<{from: string, to: string}>): Promise<Array<ResourceAvailabilityInterface>> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`resources/${resourceId}/availability`, {
                    dates: dates
                });

                resolve(res.data);
            }
            catch(error) {
                reject(error);
            }
        })
    }

}

export const resourceStore = new ResourceStore();