import { routes } from 'components/Routes';
import Table from 'components/Table';
import Cell from 'components/Table/Cell';
import { userRoleEnumToLabel } from 'helpers/UserRoleHelper';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { usersStore } from 'stores/UsersStore';
import { UserInterface } from 'types/UserInterface';

const UsersTable = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        usersStore.getUsers();

        return () => {
            usersStore.resetStore();
        }
    }, [])

    const users = usersStore.users;

    return (
        <Table
            data={users}
            defaultSortProperty='name'
            defaultSortDirection='asc'
            onRowClicked={(user: UserInterface) => navigate(generatePath(routes.pageUserDetails, { id: user.id }))}
            head={[
                {
                    label: 'Navn',
                    sortProperty: 'name'
                },
                {
                    label: 'Titel',
                    sortProperty: 'title'
                },
                {
                    label: 'Email',
                    sortProperty: 'email'
                },
                {
                    label: 'Rettigheder',
                    sortProperty: 'role',
                    rowFormat: (row: UserInterface) => userRoleEnumToLabel(row.role)
                },
            ]}
            renderRow={(user: UserInterface) => {
                return (
                    <>
                        <Cell>{user.name}</Cell>
                        <Cell>{user.title}</Cell>
                        <Cell>{user.email}</Cell>
                        <Cell>{userRoleEnumToLabel(user.role)}</Cell>
                    </>
                )
            }}
        />
    )
})

export default UsersTable;