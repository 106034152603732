import { action, makeObservable, observable } from "mobx";
import { CreateUserInterface } from "types/CreateUserInterface";
import { UserInterface } from "types/UserInterface";
import { BaseStore } from "./BaseStore";

class CreateUserStore extends BaseStore {

    user: UserInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            user: observable,
            resetStore: action,
            createUser: action
        })
    }

    resetStore = () => {
        this.user = null;
    }

    createUser = (user: CreateUserInterface): Promise<UserInterface> => {
        return this.post(`users`, user, 'user');
    }

}

export const createUserStore = new CreateUserStore();