import { useEffect, useState } from "react";
import Select, { OptionInterface } from "../Select";
import Moment from 'moment';

interface Props {
    className?: string,
    name: string
}

const SelectYear = (props: Props) => {
    const [options, setOptions] = useState<Array<OptionInterface>>([]);

    useEffect(() => {
        const opts: Array<OptionInterface> = [];
        for (var i = 2014; i <= Moment().year() + 50; i++) {
            opts.push({
                id: i.toString(),
                value: i.toString()
            })
        }
        setOptions(opts);
    }, [])

    return (
        <Select
            label='År'
            name={props.name}
            options={options}
            className={props.className || ''}
            placeholder='vælg'
        />
    )
}

export default SelectYear;