import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import FormFieldWatcher from "components/Forms/FormFieldWatcher";
import Textarea from "components/Forms/Textarea";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { notifyRecipientsModalStore } from "stores/NotifyRecipientsModalStore";
import { usersStore } from "stores/UsersStore";
import { YupInterface } from "types/YupInterface";
import RecipientRow from "./RecipientRow";
import { formDataToNotifyUserInterface } from "./RecipientRow/RecipientRowHelper";

const NotifyRecipientsModal = observer(() => {
    const [recipientsVisible, setRecipientsVisible] = useState(false);
    const isModalVisible = notifyRecipientsModalStore.isModalVisible;
    const eventId = notifyRecipientsModalStore.eventId;
    const sectionId = notifyRecipientsModalStore.sectionId;


    useEffect(() => {
        if (isModalVisible) {
            usersStore.getUsers();
        }
        else {
            usersStore.resetStore();
            setRecipientsVisible(false);
        }
    }, [isModalVisible])

    const onSubmit = async (data: any) => {
        if (!eventId) return;

        const userNoticationInfo = formDataToNotifyUserInterface(data);
        const message = data.message;

        await notifyRecipientsModalStore.notify(eventId, sectionId || null, message, userNoticationInfo);
        notifyRecipientsModalStore.closeModal();
    }

    const validation = (yup: YupInterface) => {
        return {
            message: yup.string().required('Påkrævet')
        }
    }

    const onMessageTextChanged = (value: string) => {
        setRecipientsVisible(!!value);
    }

    const users = usersStore.users;
    if (!users) return null;

    return (
        <ReactModal
            style={{
                content: {
                    maxWidth: '550px',
                    margin: '0 auto',
                    top: '40px',
                    bottom: 'auto',
                    maxHeight: '90vh'
                },
            }}
            isOpen={isModalVisible}>
            <h1 className='text-2xl'>
                Underret om ændringer
            </h1>
            <Form onSubmit={onSubmit} validation={validation}>
                <div className='mt-8'>
                    <Textarea
                        label='Beskriv kort hvilke ændringer der er foretaget'
                        name='message'
                    />
                    <FormFieldWatcher field='message' onChange={onMessageTextChanged} />
                    {
                        recipientsVisible &&
                        <>
                            <div className='flex items-center mb-4 border-b py-4'>
                                <div className='flex-1'>
                                    <h2 className='font-semibold'>
                                        Ændringer gemt - Underret brugere?
                                    </h2>
                                </div>
                                <div className='w-[60px] text-center font-semibold'>
                                    SMS
                                </div>
                                <div className='w-[60px] text-center font-semibold'>
                                    Email
                                </div>
                            </div>
                            {
                                users.map((user, index) => {
                                    return (
                                        <RecipientRow key={index} user={user} />
                                    )
                                })
                            }
                        </>
                    }
                </div>
                <div className='text-right mt-12'>
                    <Button
                        type='button'
                        onClick={() => notifyRecipientsModalStore.closeModal()}
                        appearance='secondary'
                        label='Spring over'
                        className='mr-2'
                    />
                    <Button appearance='primary' label='Send og luk' type='submit' />
                </div>
            </Form>
        </ReactModal>
    )

})

export default NotifyRecipientsModal;