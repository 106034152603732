import { action, makeObservable, observable } from "mobx";
import { UserInterface } from "types/UserInterface";
import { BaseStore } from "./BaseStore";

class UserDetailsStore extends BaseStore {

    user: UserInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            user: observable,

            resetStore: action,
            getUser: action,
            updateUser: action,
            deleteUser: action,
        })
    }

    resetStore = () => {
        this.resource = null;
    }

    getUser = (id: string) => {
        return this.get(`users/${id}`, 'user');
    }

    updateUser = (user: UserInterface) => {
        return this.put(`users/${user.id}`, user, 'user');
    }

    deleteUser = (id: string) => {
        return this.delete(`users/${id}`, id, this.user);
    }

}

export const userDetailsStore = new UserDetailsStore();