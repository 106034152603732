import Select from "components/Forms/Select";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { resourceStore } from "stores/ResourceStore";

interface Props {
    name: string,
    className?: string,
    placeholder?: string
}

const SelectResource = observer((props: Props) => {

    useEffect(() => {
        resourceStore.getResources();

        return () => {
            resourceStore.resetStore();
        }
    }, [])

    if (resourceStore.resources.length === 0) return null;

    return (
        <Select
            placeholder={props.placeholder}
            label='Lokale'
            className={props.className || ''}
            name={props.name}
            options={resourceStore.resources.map((resource) => {
                return {
                    id: resource.id,
                    value: resource.name
                }
            })}
        />
    )
})

export default SelectResource;