import request from "helpers/Request";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { CustomerListInterface } from "types/CustomerListInterface";
import { BaseStore } from "./BaseStore";

class CustomersStore extends BaseStore {

    customers: Array<CustomerListInterface> = [];
    searchQuery: string = '';

    constructor() {
        super();

        makeObservable(this, {
            customers: observable,
            searchQuery: observable,

            resetStore: action,
            getCustomers: action,
            setSearchQuery: action,

            filteredCustomers: computed
        })
    }

    resetStore = () => {
        this.customers = [];
    }

    setSearchQuery = (query: string) => {
        this.searchQuery = query;
    }

    getCustomers = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get(`customers?pageSize=999&pageNumber=1`);
                if (res.status === StatusCodes.OK) {
                    const customers = res.data?.items || [];
                    this.customers = customers;
                    resolve(customers);
                }
                else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    get filteredCustomers() {
        let customers = this.customers;

        if (this.searchQuery.length > 2) {
            const query = this.searchQuery.toLowerCase();
            customers = _.filter(customers, (e: CustomerListInterface) =>
                e.name.toLowerCase().indexOf(query) !== -1
                || e.vat?.toLowerCase().indexOf(query) !== -1
            );
        }

        return customers;
    }

}

export const customersStore = new CustomersStore();