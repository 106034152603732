import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleTrueFalse from "components/Forms/ToggleTrueFalse";
import { UserInterface } from "types/UserInterface";
import { createRowName, RecipientRowContactTypeEnum } from "./RecipientRowHelper";

interface Props {
    user: UserInterface
}

const RecipientRow = (props: Props) => {
    return (
        <div className='flex items-center mt-2 border-b py-4'>
            <div className='flex-1'>
                {props.user.name}
            </div>
            <div className='w-[60px] text-center'>
                {
                    props.user.phone &&
                    <ToggleTrueFalse
                        name={createRowName(props.user.id, RecipientRowContactTypeEnum.SMS)}
                        trueComponent={<FontAwesomeIcon icon={faThumbsUp} size='2x' color='#52cc82' />}
                        falseComponent={<FontAwesomeIcon icon={faThumbsDown} size='2x' color='#ee6161' />}
                    />
                }
            </div>
            <div className='w-[60px] text-center'>
                <ToggleTrueFalse
                    name={createRowName(props.user.id, RecipientRowContactTypeEnum.EMAIL)}
                    trueComponent={<FontAwesomeIcon icon={faThumbsUp} size='2x' color='#52cc82' />}
                    falseComponent={<FontAwesomeIcon icon={faThumbsDown} size='2x' color='#ee6161' />}
                />
            </div>
        </div>
    )
}

export default RecipientRow;