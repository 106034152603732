import { SelectMonthEnum } from "types/SelectMonthEnum";
import Select from "../Select";

interface Props {
    className?: string,
    name: string
}

const SelectMonth = (props: Props) => {

    return (
        <Select
            className={props.className || ''}
            name={props.name}
            label='Måned'
            placeholder='vælg'
            options={[
                {
                    id: SelectMonthEnum.JANUARY,
                    value: 'Januar'
                },
                {
                    id: SelectMonthEnum.FEBRUARY,
                    value: 'Februar'
                },
                {
                    id: SelectMonthEnum.MARCH,
                    value: 'Marts'
                },
                {
                    id: SelectMonthEnum.APRIL,
                    value: 'April'
                },
                {
                    id: SelectMonthEnum.MAY,
                    value: 'Maj'
                },
                {
                    id: SelectMonthEnum.JUNE,
                    value: 'Juni'
                },
                {
                    id: SelectMonthEnum.JULY,
                    value: 'Juli'
                },
                {
                    id: SelectMonthEnum.AUGUST,
                    value: 'August'
                },
                {
                    id: SelectMonthEnum.SEPTEMBER,
                    value: 'September'
                },
                {
                    id: SelectMonthEnum.OCTOBER,
                    value: 'Oktober'
                },
                {
                    id: SelectMonthEnum.NOVEMBER,
                    value: 'November'
                },
                {
                    id: SelectMonthEnum.DECEMBER,
                    value: 'December'
                },
                {
                    id: SelectMonthEnum.WHOLE_YEAR,
                    value: '🗓 Hele året'
                }
            ]}
        />
    )
}

export default SelectMonth;