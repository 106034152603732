
import request from 'helpers/Request';
import _ from 'lodash';
import { action, makeObservable } from 'mobx';
import { configure } from "mobx";
import { PagingResultInterface } from 'types/PagingResultInterface';
import SaveableStore from './SaveableStore';

configure({
    enforceActions: "never",
})

export class BaseStore extends SaveableStore {

    [key: string]: any;
    sessionProperties = []

    constructor(name?: string) {
        super(name || 'BaseStore');
        makeObservable(this, {
            getPaged: action,
            get: action,
            post: action,
            put: action,
            delete: action
        });
    }

    getPaged = async (pageableEndpoint: string, thisPage: PagingResultInterface<any> | null, classProperty: string, pageSize: number = 20) => {
        return new Promise(async (resolve, reject) => {
            try {
                let pageNumber = 1;

                if (thisPage) {
                    if (thisPage.pagination.currentPage === thisPage.pagination.pageCount) {
                        resolve(thisPage);
                        return;
                    }

                    pageNumber = thisPage.pagination.currentPage + 1;
                }

                const hasQueryParameters = pageableEndpoint.indexOf('?') !== -1;
                const firstSeperator = hasQueryParameters ? '&' : '?';

                const res = await request.get(`${pageableEndpoint}${firstSeperator}pageNumber=${pageNumber}&pageSize=${pageSize}`);

                const resData = res.data as PagingResultInterface<any>;
                if (this[classProperty] && this[classProperty]['items'] instanceof Array) {
                    const newResults = this[classProperty]['items'].concat(res.data.items);
                    resData.items = newResults;
                }

                resData.pagination.hasNextPage = resData.pagination.currentPage < resData.pagination.pageCount;

                this[classProperty] = resData;
                resolve(resData);
            }
            catch(error) {
                reject(error);
            }
        })
    }

    get = (endpoint: string, classProperty: string, force: boolean = true): any => {
        return new Promise(async (resolve, reject) => {
            try {
                if (force || (!force && (!this[classProperty] || this[classProperty].length === 0))) {
                    let res = await request.get(endpoint);
                    this[classProperty] = res.data;
                }
                resolve(this[classProperty]);
            }
            catch (error) {
                reject(error);
            }
        });
    }

    post = (endpoint: string, obj: any, target: any, useResponseObject?: boolean): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(endpoint, obj);
                const valObj = useResponseObject ? res.data : obj;

                if (!useResponseObject) {
                    valObj.id = res.data.id;
                }

                if (target instanceof Array) {
                    target.push(valObj);
                } else {
                    target = valObj;
                }

                resolve(valObj);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    put = (endpoint: string, obj: any, target: any, useResponseObject?: boolean) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(endpoint, obj);
                const valObj = useResponseObject ? res.data : obj;
                if (this[target] instanceof Array) {
                    const index = _.findIndex(this[target], { id: valObj.id });
                    this[target].splice(index, 1, valObj);
                }
                else {
                    this[target] = valObj;
                }

                resolve(obj);
            }
            catch (error) {
                reject(error);
            }
        })
    }

    delete = (endpoint: string, id: string, target: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                await request.delete(endpoint);
                if (target instanceof Array) {
                    _.remove(target, (t: any) => t.id === id);
                }
                else {
                    target = null;
                }

                resolve(true);
            }
            catch (error) {
                reject(error);
            }
        });
    }
}
