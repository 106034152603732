import { action, makeObservable, observable } from "mobx";
import { ContactListInterface } from "types/ContactListInterface";
import { CreateContactInterface } from "types/CreateContactInterface";
import { CustomerListInterface } from "types/CustomerListInterface";
import { BaseStore } from "./BaseStore";

class CustomerDetailsStore extends BaseStore {

    customer: CustomerListInterface | null = null;
    contacts: Array<ContactListInterface> = [];

    constructor() {
        super();
        makeObservable(this, {
            customer: observable,
            contacts: observable,

            resetStore: action,
            getCustomer: action,
            updateCustomer: action,
            deleteCustomer: action,
            getContacts: action,
            updateContact: action,
            deleteContact: action,
            createContact: action
        })
    }

    resetStore = () => {
        this.customer = null;
        this.contacts = [];
    }

    getCustomer = (id: string) => {
        return this.get(`customers/${id}`, 'customer');
    }

    updateCustomer = (customer: CustomerListInterface) => {
        return this.put(`customers/${customer.id}`, customer, 'customer');
    }

    deleteCustomer = (id: string) => {
        return this.delete(`customers/${id}`, id, this.customer);
    }

    getContacts = (customerId: string) => {
        return this.get(`customers/${customerId}/contacts`, 'contacts');
    }

    updateContact = (customerId: string, contact: ContactListInterface) => {
        return this.put(`customers/${customerId}/contacts/${contact.id}`, contact, 'contacts');
    }

    deleteContact = (customerId: string, contactId: string) => {
        return this.delete(`customers/${customerId}/contacts/${contactId}`, contactId, this.contacts);
    }

    createContact = (customerId: string, contact: CreateContactInterface) => {
        return this.post(`customers/${customerId}/contacts`, contact, this.contacts, true)
    }

}

export const customerDetailsStore = new CustomerDetailsStore();