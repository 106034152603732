import ResourceGeneralForm from "components/Forms/ResourceGeneralForm";
import { ResourceGeneralFormFieldEnum } from "components/Forms/ResourceGeneralForm/ResourceGeneralFormFieldEnum";
import Header from "components/Header";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createResourceStore } from "stores/CreateResourceStore";

const PageCreateResource = () => {
    const navigate = useNavigate();

    const onSubmit = async (data: { [x in ResourceGeneralFormFieldEnum]: string; }) => {
        await createResourceStore.createResource({
            name: data.name,
            description: data.description,
            color: data.color || ''
        })

        navigate(routes.pageSettingsResources);
        toast('Lokale oprettet', { type: 'success' });
    }

    return (
        <div>
            <Header
                onBack={() => navigate(-1)}
            />
            <TwoColumnWrapper
                title='Opret lokale'
                leftColumn={
                    <ResourceGeneralForm
                        onSubmit={onSubmit}
                        onCancel={() => navigate(-1)}
                        showActionButtonsByDefault={true}
                    />
                }
            />
        </div>
    )

}

export default PageCreateResource;