import { action, makeObservable, observable } from "mobx";
import { CreateEventInterface } from "types/CreateEventInterface";
import { EventListInterface } from "types/EventListInterface";
import { BaseStore } from "./BaseStore";

class CreateEventStore extends BaseStore {

    date: Date | null = null;
    event: EventListInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            date: observable,
            event: observable,

            resetStore: action,
            createEvent: action,
            setDate: action
        })
    }

    resetStore = () => {
        this.event = null;
        this.date = null;
    }

    createEvent = (event: CreateEventInterface): Promise<EventListInterface> => {
        return this.post(`events`, event, 'event');
    }

    setDate = (date: Date | null) => {
        this.date = date;
    }

}

export const createEventStore = new CreateEventStore();