import { UserRoleEnum } from "types/UserRoleEnum";

export const userRoleEnumToLabel = (userRole: UserRoleEnum) => {
    switch (userRole) {
        case UserRoleEnum.ADMIN: return 'Administrator';
        case UserRoleEnum.USER: return 'Læseadgang';
        case UserRoleEnum.READ: return 'Læseadgang (API)';
        case UserRoleEnum.WRITE: return 'Læse og skriveadgang (API)';
        default: return '';
    }
}