import Form from 'components/Forms/Form';
import Header from 'components/Header';
import TwoColumnWrapper from 'components/TwoColumnWrapper';
import WhiteBox from 'components/WhiteBox';
import { useNavigate } from 'react-router-dom';
import { ApiTokenFormFieldEnum } from './ApiTokenFormFieldEnum';
import { YupInterface } from 'types/YupInterface';
import Input from 'components/Forms/Input';
import SelectApiRole from 'components/Forms/SelectApiRole';
import Button from 'components/Forms/Button';
import { apiTokensStore } from 'stores/ApiTokensStore';
import { routes } from 'components/Routes';
import { useState } from 'react';
import ReactModal from 'react-modal';

const PageCreateApiToken = () => {
    const [bearerToken, setBearerToken] = useState<string | null>(null);

    const navigate = useNavigate();

    const onSubmit = async (data: { [x in ApiTokenFormFieldEnum]: string; }) => {
        const newToken = await apiTokensStore.createToken({
            expiryInYears: 2,
            role: data.role as any,
            tokenName: data.tokenName,
            userName: data.userName
        })

        setBearerToken(newToken.jwtToken);
    }

    const validation = (yup: YupInterface) => {
        return {
            [ApiTokenFormFieldEnum.TOKEN_NAME]: yup.string().required('Påkrævet'),
            [ApiTokenFormFieldEnum.USER_NAME]: yup.string().required('Påkrævet'),
            [ApiTokenFormFieldEnum.ROLE]: yup.string().required('Påkrævet')
        }
    }

    return (
        <div>
            <Header
                onBack={() => navigate(-1)}
            />

            <TwoColumnWrapper
                title='Ny API adgang'
                leftColumn={
                    <WhiteBox className='mb-4 p-6'>
                        <div className='flex items-center place-content-between'>
                            <h1 className='text-xl'>
                                Generelt
                            </h1>
                        </div>
                        <Form
                            onSubmit={onSubmit}
                            validation={validation}
                        >
                            <div className='grid gap-8 grid-cols-2 mt-6'>
                                <div>
                                    <Input name={ApiTokenFormFieldEnum.TOKEN_NAME} label='Virksomhed (eks. Uniconta)' />
                                    <Input name={ApiTokenFormFieldEnum.USER_NAME} label='Kontaktperson (eks. Lars Larsen)' />
                                    <SelectApiRole
                                        placeholder='Vælg rettighed'
                                        name={ApiTokenFormFieldEnum.ROLE}
                                    />
                                </div>
                            </div>
                            <div className='text-right'>
                                <Button
                                    onClick={() => navigate(-1)}
                                    type='button'
                                    appearance='secondary'
                                    label='Fortryd'
                                    className='mr-4'
                                />

                                <Button
                                    type='submit'
                                    label='Gem'
                                    appearance='primary'
                                />
                                </div>

                        </Form>
                    </WhiteBox>
                }
            />

            <ReactModal
                style={{
                    content: {
                    maxWidth: '550px',
                    margin: '0 auto',
                    top: '40px',
                    bottom: 'auto',
                    // height: '300px'
                },
            }}
            isOpen={bearerToken !== null}>
                <h1 className='text-2xl'>
                    API adgang oprettet!
                </h1>
                <p className='my-6'>Kopier og benyt følgende API nøgle for adgang <span className='font-semibold'>(Vises kun én gang!)</span></p>
                <div className='w-full break-all p-4 bg-gray-100 rounded'>
                    {bearerToken}
                </div>
                <div className='text-right mt-12'>
                    <Button appearance='primary' label='Tilbage til oversigt' type='button' onClick={() => navigate(routes.pageSettingsApiTokens)} />
                </div>
            </ReactModal>
        </div>
    )

}

export default PageCreateApiToken;