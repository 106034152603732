import _ from "lodash";
import { NotifyUserInterface } from "types/NotifyUserInterface";

export enum RecipientRowContactTypeEnum {
    EMAIL = 'email',
    SMS = 'sms'
}

export const createRowName = (userId: string, type: RecipientRowContactTypeEnum) => {
    return `${userId};${type}`
}

export const formDataToNotifyUserInterface = (formData: { [key: string]: boolean }) => {
    const resultArr: Array<NotifyUserInterface> = [];



    Object.keys(formData).forEach((formKey) => {
        if (formKey.indexOf(';') !== -1) {
            const [userId, type] = formKey.split(';');

            const existingUser = _.find(resultArr, (r) => r.user.id === userId);
            if (existingUser) {
                if (type === RecipientRowContactTypeEnum.EMAIL) {
                    existingUser.notifyOnEmail = formData[formKey] || false;
                }
                else if (type === RecipientRowContactTypeEnum.SMS) {
                    existingUser.notifyOnPhone = formData[formKey] || false;
                }
            }
            else {
                resultArr.push({
                    user: {
                        id: userId
                    },
                    notifyOnEmail: type === RecipientRowContactTypeEnum.EMAIL ? formData[formKey] : false,
                    notifyOnPhone: type === RecipientRowContactTypeEnum.SMS ? formData[formKey] : false,
                } as NotifyUserInterface);
            }
        }
    })

    return resultArr;
}