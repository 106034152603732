import CustomerGeneralForm from "components/Forms/CustomerGeneralForm";
import { CustomerGeneralFormFieldEnum } from "components/Forms/CustomerGeneralForm/CustomerGeneralFormFieldEnum";
import ReactModal from "react-modal";
import { createCustomerStore } from "stores/CreateCustomerStore";
import { CustomerListInterface } from "types/CustomerListInterface";

interface Props {
    afterSubmit: (customer: CustomerListInterface) => void,
    onCancel: () => void,
    newCustomerName?: string
}

const CreateCustomerModal = (props: Props) => {

    const onSubmit = async (data: { [x in CustomerGeneralFormFieldEnum]: string }) => {
        const newCustomer = await createCustomerStore.createCustomer({
            name: data.name,
            vat: data.vat,
            address: data.address,
            zip: data.zip,
            city: data.city,
        })

        props.afterSubmit(newCustomer);
    }

    return (
        <ReactModal
            style={{
                content: {
                    maxWidth: '550px',
                    margin: '0 auto',
                    top: '40px',
                    bottom: 'auto',
                    maxHeight: '90vh'
                },
            }}
            isOpen={true}>
            <CustomerGeneralForm
                showActionButtonsByDefault={true}
                onCancel={props.onCancel}
                onSubmit={onSubmit}
                defaultValues={{
                    [CustomerGeneralFormFieldEnum.NAME]: props.newCustomerName
                }}
            />
        </ReactModal>
    )

}

export default CreateCustomerModal;