import request from "helpers/Request";
import { StatusCodes } from "http-status-codes";
import { action, makeObservable, observable } from "mobx";
import { UserInterface } from "types/UserInterface";
import { BaseStore } from "./BaseStore";

class UsersStore extends BaseStore {

    users: Array<UserInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            users: observable,

            resetStore: action,
            getUsers: action
        })
    }

    resetStore = () => {
        this.users = [];
    }

    getUsers = () => {
        return new Promise(async(resolve, reject) => {
            try {
                const res = await request.get(`users?pageNumber=1&pageSize=999`);
                if (res.status === StatusCodes.OK) {
                    const users = res.data.items;
                    this.users = users;
                    resolve(users);
                }
                else reject();
            }
            catch(error) {
                reject(error);
            }
        })
    }
}

export const usersStore = new UsersStore();