import Button from "components/Forms/Button";
import Header, { ActiveHeaderEnum } from "components/Header";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { notificationsStore } from "stores/NotificationsStore";
import NotificationBox from "./NotificationBox";

const PageUserNotifications = observer(() => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const getNotifications = async () => {
            await notificationsStore.getNotifications();
            setIsLoaded(true);
        }

        getNotifications();

        return () => {
            notificationsStore.resetStore();
        }
    }, [])

    const notifications = notificationsStore.notifications?.items || [];

    return (
        <div>
            <Header activeMenu={ActiveHeaderEnum.MESSAGES} />
            <div className='mx-2 md:mx-12 '>
                <div className='mt-6 md:mt-12 max-w-[700px] mx-auto p-10 md:p-0'>
                    {
                        isLoaded &&
                        <h1 className='text-2xl mb-8'>
                            Underretninger
                        </h1>
                    }

                    {
                        notifications?.map((notification, index) => {
                            return (
                                <NotificationBox
                                    key={index}
                                    notification={notification}
                                />
                            )
                        })
                    }
                    {
                        (isLoaded && notifications.length === 0) &&
                        <div>
                            Du har ikke modtaget nogle underretninger endnu 🙂
                        </div>
                    }

                    {
                        notificationsStore.notifications?.pagination.hasNextPage &&
                        <div>
                            <Button
                                appearance='primary'
                                type='button'
                                label='Indlæs flere'
                                onClick={() => notificationsStore.getNotifications()}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )

})

export default PageUserNotifications;