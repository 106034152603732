import CustomerGeneralForm from "components/Forms/CustomerGeneralForm";
import { CustomerGeneralFormFieldEnum } from "components/Forms/CustomerGeneralForm/CustomerGeneralFormFieldEnum";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ReactModal from "react-modal";
import { customerDetailsStore } from "stores/CustomerDetailsStore";
import { CustomerListInterface } from "types/CustomerListInterface";

interface Props {
    onClose: (customer: CustomerListInterface) => void,
    customerId: string | null
}

const CustomerDetailsModal = observer((props: Props) => {

    const customerId = props.customerId;
    useEffect(() => {
        if (customerId) {
            customerDetailsStore.getCustomer(customerId);
        }
        else {
            customerDetailsStore.resetStore();
        }

        return () => {
            customerDetailsStore.resetStore();
        }
    }, [customerId])

    const onSubmit = async (data: { [x in CustomerGeneralFormFieldEnum]: string }) => {
        const res = await customerDetailsStore.updateCustomer({
            id: props.customerId!,
            name: data.name,
            vat: data.vat,
            ean: data.ean,
            type: data.type,
            phone: data.phone,
            email: data.email,
            customerNumber: data.customerNumber,
            address: data.address,
            zip: data.zip,
            city: data.city,
            website: data.website,
            note: data.note,
        }) as CustomerListInterface;

        props.onClose(res);
    }

    const customer = customerDetailsStore.customer;
    if (!customer) return null;

    return (
        <ReactModal
            style={{
                content: {
                    maxWidth: '550px',
                    margin: '0 auto',
                    top: '40px',
                    bottom: 'auto',
                    maxHeight: '90vh'
                },
            }}
            isOpen={true}>
            <CustomerGeneralForm
                showActionButtonsByDefault={true}
                onCancel={() => props.onClose(customer)}
                onSubmit={onSubmit}
                defaultValues={{
                    [CustomerGeneralFormFieldEnum.NAME]: customer.name,
                    [CustomerGeneralFormFieldEnum.VAT]: customer.vat,
                    [CustomerGeneralFormFieldEnum.EAN]: customer.ean,
                    [CustomerGeneralFormFieldEnum.TYPE]: customer.type,
                    [CustomerGeneralFormFieldEnum.PHONE]: customer.phone,
                    [CustomerGeneralFormFieldEnum.EMAIL]: customer.email,
                    [CustomerGeneralFormFieldEnum.CUSTOMER_NUMBER]: customer.customerNumber,
                    [CustomerGeneralFormFieldEnum.ADDRESS]: customer.address,
                    [CustomerGeneralFormFieldEnum.ZIP]: customer.zip,
                    [CustomerGeneralFormFieldEnum.CITY]: customer.city,
                    [CustomerGeneralFormFieldEnum.WEBSITE]: customer.website,
                    [CustomerGeneralFormFieldEnum.NOTE]: customer.note,
                }}
            />
        </ReactModal>
    )
})

export default CustomerDetailsModal;
