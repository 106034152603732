import { isString } from "lodash";

export const getErrorMessage = (err: any) => {
    // if err is a string
    if (isString(err)) {
        return err;
    }

    // if error is from a response
    if (err.response?.data?.error && isString(err.response.data.error)) {
        return err.response.data.error;
    }

    // fallback
    return 'An error occured';
}