import { action, computed, makeObservable, observable } from "mobx";
import SaveableStore from "./SaveableStore";

class RedirectStore extends SaveableStore {

    location: string | null = null;

    constructor() {
        super('RedirectStore');
        
        makeObservable(this, {
            location: observable,

            resetStore: action,
            setLocation: action,

            redirectIsActive: computed
        });

        this.initSessionStorage(this, ['location']);
    }

    resetStore = () => {
        this.location = null;
    }

    setLocation = (value: string) => {
        this.location = value;
    }

    get redirectIsActive() {
        return !!this.location;
    }

}

export const redirectStore = new RedirectStore();