import UploadFile from "components/UploadFile";
import WhiteBox from "components/WhiteBox";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { authStore } from "stores/AuthStore";
import { eventDetailsStore } from "stores/EventDetailsStore";
import { notifyRecipientsModalStore } from "stores/NotifyRecipientsModalStore";
import FileInterface from "types/FileInterface";
import { UserRoleEnum } from "types/UserRoleEnum";

interface Props {
    eventId: string
}

const EventFiles = observer((props: Props) => {

    const eventId = props.eventId;
    useEffect(() => {
        eventDetailsStore.getFiles(eventId)
    }, [eventId])

    const onDeleteFile = async (fileId: string) => {
        if (!window.confirm('Er du sikker på at du vil slette denne fil?')) return;
        await eventDetailsStore.deleteFile(eventId, fileId)
    }

    const onOpenFile = (fileUrl: string) => {
        window.open(fileUrl);
    }

    const onFileUploaded = async (file: FileInterface) => {
        await eventDetailsStore.addFile(eventId, file.id);
        notifyRecipientsModalStore.showModal(eventId);
    }

    const files = eventDetailsStore.files;

    return (
        <>
            {
                files.length > 0 &&
                <>
                    <div className='hidden print:flex items-center gap-2 text-sm pt-4'>
                        <span>Filer: </span>
                        {files.map(f => f.name).join(', ')}
                    </div>
                    <WhiteBox className='print:hidden mb-4 p-6 relative' >
                        <h1 className='text-xl w-full pr-12 mb-6'>
                            Filer
                        </h1>
                        <ul>
                            {
                                files.map((file, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className='
                                            flex 
                                            items-center 
                                            bg-gray-100 
                                            hover:bg-gray-200 
                                            cursor-pointer 
                                            rounded my-2'
                                        >
                                            <div className='py-2 px-2 flex-1' onClick={() => onOpenFile(file.fileUrl)}>
                                                {file.name}
                                            </div>
                                            <div className='cursor-auto'>
                                                <button
                                                    type='button'
                                                    onClick={() => onDeleteFile(file.id)}
                                                    className='
                                                    mr-2
                                                    bg-[#DADADA]
                                                    hover:bg-[#AAAAAA] 
                                                    px-2 
                                                    py-1 
                                                    text-xs 
                                                    rounded'>
                                                    Slet
                                                </button>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </WhiteBox>
                </>
            }

            {
                authStore.user?.role === UserRoleEnum.ADMIN &&
                <UploadFile onSubmit={onFileUploaded}>
                    <WhiteBox className='mb-4 p-6 opacity-30 hover:opacity-80 cursor-pointer print:hidden'>
                        <div className='flex items-center place-content-between'>
                            <h1 className='text-xl'>
                                Tilføj fil
                            </h1>
                        </div>

                    </WhiteBox>
                </UploadFile>
            }


        </>
    )

})

export default EventFiles;