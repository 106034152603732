import Button from 'components/Forms/Button';
import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import NotLoggedInContainer from 'components/NotLoggedInContainer';
import { routes } from 'components/Routes';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { YupInterface } from 'types/YupInterface';

const PageResetPassword = () => {
    const [isComplete, setIsComplete] = useState(false);
    const navigate = useNavigate();

    const { token } = useParams<{ token: string }>();

    const onSubmit = async (data: any) => {
        if (!token) return;

        if (data.password1 !== data.password2) {
            toast('De to password er ikke ens. Du skal indtaste det samme password i begge felter.', { type: 'error', autoClose: 5000 });
            return;
        }

        try {
            await authStore.setPassword(token, data.password1);
            setIsComplete(true);
        }
        catch (error) {
            toast('Der opstod en fejl', { type: 'error' });
        }

    }

    const validation = (yup: YupInterface) => {
        return {
            password1: yup.string().required('Password er påkrævet'),
            password2: yup.string().required('Password er påkrævet')
        }
    }

    return (
        <NotLoggedInContainer
            title='Sæt et nyt password til din bruger'>
            {
                !isComplete &&
                <Form onSubmit={onSubmit} validation={validation}>
                    <Input
                        label='Nyt password'
                        name='password1'
                        type='password'
                    />
                    <Input
                        label='Nyt password igen'
                        name='password2'
                        type='password'
                    />
                    <div className='text-right pt-4'>
                        <Button
                            type='submit'
                            appearance='primary'
                            label='Gem'
                        />
                    </div>
                </Form>
            }

            {
                isComplete &&
                <p>
                    Dit password er blevet ændret.
                    <button
                        className='ml-1 underline text-light-blue'
                        onClick={() => navigate(routes.pageLogin)}>
                        Gå til forside
                    </button> for at logge ind 😀
                </p>
            }

        </NotLoggedInContainer>
    )
}

export default PageResetPassword;