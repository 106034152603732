import { userRoleEnumToLabel } from "helpers/UserRoleHelper";
import { UserRoleEnum } from "types/UserRoleEnum";
import Select from "../Select";
import { useFormContext } from 'react-hook-form';

interface Props {
    className?: string,
    name: string,
    placeholder?: string
}

const SelectApiRole = (props: Props) => {
    const { watch } = useFormContext();
    const val = watch(props.name);

    return (
        <>
        <Select
            label='Rettighed'
            className={props.className || ''}
            name={props.name}
            placeholder={props.placeholder}
            options={[
                {
                    id: UserRoleEnum.READ.toString(),
                    value: userRoleEnumToLabel(UserRoleEnum.READ)
                },
                {
                    id: UserRoleEnum.WRITE.toString(),
                    value: userRoleEnumToLabel(UserRoleEnum.WRITE)
                }
            ]}
        />
        {
            Number(val) === UserRoleEnum.WRITE &&
            <div className='bg-red-800 text-white p-2 text-sm rounded mt-2'>
                <p>
                    <span className='font-semibold'>VIGTIGT:</span> Denne rettighed giver adgang til at foretage ændringer direkte i Teateradmin.dk fra eksterne systemer. Vær sikker på at du ved hvad du gør. 
                </p>
                <p className='mt-2'>
                    Nordicode yder ikke support på på ændringer foretaget via API.
                </p>
            </div>
        }
        </>
    )

}

export default SelectApiRole;