import ContactGeneralForm from "components/Forms/ContactGeneralForm";
import { ContactGeneralFormFieldEnum } from "components/Forms/ContactGeneralForm/ContactGeneralFormFieldEnum";
import ReactModal from "react-modal";
import { createContactStore } from "stores/CreateContactStore";
import { ContactListInterface } from "types/ContactListInterface";

interface Props {
    afterSubmit: (contact: ContactListInterface) => void,
    onCancel: () => void,
    newContactName?: string,
    customerId: string
}

const CreateContactModal = (props: Props) => {

    const onSubmit = async (data: { [x in ContactGeneralFormFieldEnum]: string }) => {
        const newContact = await createContactStore.createContact(props.customerId, {
            name: data.name,
            mobile: data.mobile,
            phone: data.phone,
            email: data.email,
            title: data.title
        })

        props.afterSubmit(newContact);
    }

    return (
        <ReactModal
            style={{
                content: {
                    maxWidth: '550px',
                    margin: '0 auto',
                    top: '40px',
                    bottom: 'auto',
                    maxHeight: '90vh'
                },
            }}
            isOpen={true}>
            <ContactGeneralForm
                showActionButtonsByDefault={true}
                onCancel={props.onCancel}
                onSubmit={onSubmit}
                defaultValues={{
                    [ContactGeneralFormFieldEnum.NAME]: props.newContactName
                }}
            />
        </ReactModal>
    )

}

export default CreateContactModal;