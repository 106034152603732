import ResourcesTable from "components/ResourcesTable";
import { routes } from "components/Routes";
import SettingsWrapper, { HeaderSubMenuItemEnum } from "components/SettingsWrapper";
import { useNavigate } from "react-router-dom";

const PageSettingsResources = () => {
    const navigate = useNavigate();

    return (
        <SettingsWrapper 
            title='Lokaler' 
            actionButton={{ label: 'Opret lokale', onClick: () => navigate(routes.pageCreateResource) }}
            activeSubMenuItem={HeaderSubMenuItemEnum.RESOURCES}>
            <ResourcesTable />
        </SettingsWrapper>
    )
}

export default PageSettingsResources;