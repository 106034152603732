import ContactGeneralForm from "components/Forms/ContactGeneralForm";
import { ContactGeneralFormFieldEnum } from "components/Forms/ContactGeneralForm/ContactGeneralFormFieldEnum";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import ReactModal from "react-modal";
import { contactDetailsStore } from "stores/ContactDetailsStore";
import { ContactListInterface } from "types/ContactListInterface";

interface Props {
    onClose: (contact: ContactListInterface) => void,
    customerId: string | null
    contactId?: string | null
}

const ContactDetailsModal = observer((props: Props) => {

    const { contactId, customerId } = props;
    useEffect(() => {
        if (contactId && customerId) {
            contactDetailsStore.getContact(customerId, contactId)
        }
        else {
            contactDetailsStore.resetStore();
        }

        return () => {
            contactDetailsStore.resetStore();
        }
    }, [contactId, customerId])

    const onSubmit = async (data: { [x in ContactGeneralFormFieldEnum]: string; }) => {
        const res = await contactDetailsStore.updateContact(customerId!, {
            id: contactId || '',
            email: data.email,
            mobile: data.mobile,
            name: data.name,
            phone: data.phone,
            title: data.title
        }) as ContactListInterface;

        props.onClose(res);
    }

    const contact = contactDetailsStore.contact;
    if (!contact) return null;

    return (
        <ReactModal
            style={{
                content: {
                    maxWidth: '550px',
                    margin: '0 auto',
                    top: '40px',
                    bottom: 'auto',
                    maxHeight: '90vh'
                },
            }}
            isOpen={true}>
            <ContactGeneralForm
                showActionButtonsByDefault={true}
                onCancel={() => props.onClose(contact)}
                onSubmit={onSubmit}
                defaultValues={{
                    [ContactGeneralFormFieldEnum.NAME]: contact.name,
                    [ContactGeneralFormFieldEnum.MOBILE]: contact.mobile,
                    [ContactGeneralFormFieldEnum.PHONE]: contact.phone,
                    [ContactGeneralFormFieldEnum.EMAIL]: contact.email,
                    [ContactGeneralFormFieldEnum.TITLE]: contact.title
                }}
            />
        </ReactModal>
    )
})

export default ContactDetailsModal;