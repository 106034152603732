import ExpandButton from "components/ExpandButton";
import CustomerGeneralForm from "components/Forms/CustomerGeneralForm";
import { CustomerGeneralFormFieldEnum } from "components/Forms/CustomerGeneralForm/CustomerGeneralFormFieldEnum";
import Header from "components/Header";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { customerDetailsStore } from "stores/CustomerDetailsStore";
import _ from 'lodash';
import { toast } from "react-toastify";
import { routes } from "components/Routes";
import CustomerContactList from "./CustomerContactList";

const PageCustomerDetails = observer(() => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            customerDetailsStore.getCustomer(id);
        }
    }, [id])

    useEffect(() => {
        return () => {
            customerDetailsStore.resetStore();
        }
    }, [])

    const onSaveGeneral = async (data: { [x in CustomerGeneralFormFieldEnum]: string; }) => {
        const customer = _.clone(customerDetailsStore.customer);
        if (!customer) return;

        customer.name = data.name;
        customer.vat = data.vat;
        customer.address = data.address;
        customer.zip = data.zip;
        customer.city = data.city;

        await customerDetailsStore.updateCustomer(customer);
        toast('Ændringer gemt', { type: 'success' });
    }

    const onDeleteCustomer = async () => {
        if (!window.confirm('Er du sikker på at du vil slette denne kunde?')) return;

        await customerDetailsStore.deleteCustomer(id!);
        navigate(routes.pageSettingsCustomers);
    }

    const customer = customerDetailsStore.customer;
    if (!customer) return null;

    return (
        <div>
            <Header onBack={() => navigate(-1)} />
            <TwoColumnWrapper
                skipNotifyModal={true}
                title='Kunde'
                topRight={
                    <ExpandButton
                        label='Handlinger'
                        items={[
                            {
                                label: 'Slet kunde',
                                onClick: () => onDeleteCustomer()
                            }
                        ]}
                    />
                }
                leftColumn={
                    <CustomerGeneralForm
                        defaultValues={{
                            [CustomerGeneralFormFieldEnum.NAME]: customer.name,
                            [CustomerGeneralFormFieldEnum.VAT]: customer.vat,
                            [CustomerGeneralFormFieldEnum.ADDRESS]: customer.address,
                            [CustomerGeneralFormFieldEnum.ZIP]: customer.zip,
                            [CustomerGeneralFormFieldEnum.CITY]: customer.city,
                        }}
                        onSubmit={onSaveGeneral}
                    />
                }
                rightColumn={
                    <CustomerContactList customerId={id!} />
                }
            />
        </div>
    )

})

export default PageCustomerDetails;