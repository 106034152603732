import ExpandButton from "components/ExpandButton";
import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import { ContactGeneralFormFieldEnum } from "./ContactGeneralFormFieldEnum";

interface Props {
    defaultValues?: { [x in ContactGeneralFormFieldEnum]?: any },
    onSubmit: (data: { [x in ContactGeneralFormFieldEnum]: string; }) => void,
    showActionButtonsByDefault?: boolean,
    onCancel?: () => void,
    onDelete?: () => void
}

const ContactGeneralForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [ContactGeneralFormFieldEnum.NAME]: yup.string().required('Påkrævet')
        }
    }

    return (
        <>
            <WhiteBox
                className='mb-4 p-6 relative'>
                <Form
                    formOptions={{ defaultValues: props.defaultValues }}
                    validation={validation}
                    onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
                    onSubmit={props.onSubmit} >
                    <div className='flex items-center place-content-between mb-8'>
                        <h1 className='pr-12 w-full'>
                            <Input name={ContactGeneralFormFieldEnum.NAME} label='Navn' />
                        </h1>
                        {
                            props.onDelete &&
                            <div>
                                <ExpandButton
                                    label=''
                                    items={[
                                        {
                                            label: 'Slet',
                                            onClick: () => props.onDelete!()
                                        }
                                    ]}
                                />
                            </div>
                        }

                    </div>
                    <div className='grid gap-8 grid-cols-2 mt-6'>
                        <div>
                            <Input name={ContactGeneralFormFieldEnum.TITLE} label='Titel' />
                            <Input name={ContactGeneralFormFieldEnum.EMAIL} label='Email' />
                        </div>
                        <div>
                            <Input name={ContactGeneralFormFieldEnum.MOBILE} label='Mobil' />
                            <Input name={ContactGeneralFormFieldEnum.PHONE} label='Direkte' />
                        </div>
                    </div>
                    {
                        (props.showActionButtonsByDefault || isFormDirty) &&
                        <div className='text-right'>
                            {
                                props.onCancel &&
                                <Button
                                    onClick={() => props.onCancel!()}
                                    type='button'
                                    appearance='secondary'
                                    label='Fortryd'
                                    className='mr-4'
                                />
                            }

                            <Button
                                type='submit'
                                label='Gem'
                                appearance='primary'
                            />
                        </div>
                    }
                </Form>
            </WhiteBox>


        </>
    )

}

export default ContactGeneralForm;