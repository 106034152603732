import { routes } from "components/Routes";
import moment from "moment";
import { generatePath, useNavigate } from "react-router-dom";
import { NotificationInterface } from "types/NotificationInterface";

interface Props {
    notification: NotificationInterface
}

const NotificationBox = (props: Props) => {
    const navigate = useNavigate();

    const notification = props.notification;
    const event = notification.event;
    const momentFrom = moment(event.from);
    const momentTo = moment(event.to)

    const dateStr = `${momentFrom.format('YY-MM-DD')} ${!momentFrom.isSame(momentTo, 'date') ? ` - ${momentTo.format('YY-MM-DD')}` : ''}`;
    const timeStr = `${momentFrom.format('HH:mm')} - ${momentTo.format('HH:mm')}`;

    return (
        <div
            onClick={() => navigate(generatePath(routes.pageEventDetails, { id: event.id }))}
            className={`
            bg-white
            rounded-[12px]
            p-4
            mb-8
            cursor-pointer
        `}>
            <h1 className='text-lg font-semibold'>
                {event.title} ({dateStr} {timeStr})
            </h1>
            <p className='text-sm mt-1'>
                {event.location?.name}
            </p>
            <div className='pl-4 py-4 my-4' style={{ borderLeft: '2px solid #589CCD' }}>
                {notification.message}
            </div>
            <div className='text-xs mt-8 italic'>
                {moment(notification.createdOn).format('YY-MM-DD HH:mm')} af {notification.createdBy?.name}
                {
                    notification.sections.length > 0 &&
                    <span className='text-light-blue font-semibold'>
                         :&nbsp;{notification.sections[0].title}
                    </span>
                }
            </div>
        </div>
    )
}

export default NotificationBox;