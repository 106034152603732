import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { EventListInterface } from "types/EventListInterface";
import { EventStatusEnum } from "types/EventStatusEnum";
import { BaseStore } from "./BaseStore";
import _ from 'lodash';
import { SelectMonthEnum } from "types/SelectMonthEnum";

class EventsStore extends BaseStore {

    events: Array<EventListInterface> = [];

    // Filters
    year: string = moment().year().toString();
    month: string = moment().month().toString();
    selectedResourceDate: Date = moment().startOf('month').toDate();

    resourceId: string = '';
    status: EventStatusEnum | '' = '';
    searchQuery: string = '';

    constructor() {
        super();
        makeObservable(this, {
            events: observable,
            year: observable,
            month: observable,
            selectedResourceDate: observable,
            resourceId: observable,
            status: observable,
            searchQuery: observable,

            getEvents: action,
            setYear: action,
            setMonth: action,
            setResourceId: action,
            setSelectedResourceDate: action,
            setStatus: action,
            setSearchQuery: action,

            filteredEvents: computed
        });
    }

    getEvents = () => {
        let from = moment().year(parseInt(this.year)).month(parseInt(this.month)).startOf('month').toISOString();
        let to = moment().year(parseInt(this.year)).month(parseInt(this.month)).endOf('month').toISOString();

        if (this.month === SelectMonthEnum.WHOLE_YEAR) {
            from = moment().year(parseInt(this.year)).startOf('year').toISOString();
            to = moment().year(parseInt(this.year)).endOf('year').toISOString();            
        }

        return this.get(`events?from=${from}&to=${to}`, 'events');
    }

    getEventsByYear = (year: string) => {
        const from = moment().year(parseInt(year)).startOf('year').toISOString();
        const to = moment().year(parseInt(year)).endOf('year').toISOString();

        return this.get(`events?from=${from}&to=${to}`, 'events');
    }

    setYear = (year: string, getEvents: boolean = true) => {
        this.year = year;

        const momentDate = moment(this.selectedResourceDate);
        if (momentDate.year() !== Number(year)) {
            this.setSelectedResourceDate(moment(this.selectedResourceDate).year(Number(year)).toDate());
        }

        if (getEvents) {
            this.getEvents();
        }
    }

    setMonth = (month: string, getEvents: boolean = true) => {
        this.month = month;

        const momentDate = moment(this.selectedResourceDate);
        if (momentDate.month() !== Number(month)) {
            this.setSelectedResourceDate(moment(this.selectedResourceDate).month(month).toDate());
        }

        if (getEvents) {
            this.getEvents();
        }
    }

    setResourceId = (id: string) => {
        this.resourceId = id;
    }

    setSelectedResourceDate = (date: Date) => {
        this.selectedResourceDate = date;
    }

    setStatus = (status: EventStatusEnum | '') => {
        this.status = status;
    }

    setSearchQuery = (query: string) => {
        this.searchQuery = query;
    }

    get filteredEvents() {
        let events = this.events;

        if (this.resourceId) {
            events = _.filter(events, (e) => e.location.id === this.resourceId);
        }

        if (this.status !== '') {
            events = _.filter(events, (e) => e.status === this.status);
        }

        if (this.searchQuery.length > 2) {
            const query = this.searchQuery.toLowerCase();
            events = _.filter(events, (e: EventListInterface) =>
                e.title.toLowerCase().indexOf(query) !== -1
                || (e.customer && e.customer?.name.toLowerCase().indexOf(query) !== -1)
            );
        }

        return events;
    }

}

export const eventsStore = new EventsStore();