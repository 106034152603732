import EventsListCalendarWrapper, { HeaderSubMenuItemEnum } from "components/EventsListCalendarWrapper"
import Form from "components/Forms/Form"
import FormFieldWatcher from "components/Forms/FormFieldWatcher"
import ResourceGrid from "components/ResourceGrid"
import { eventsStore } from "stores/EventsStore"
import { useEffect, useState } from "react"
import SelectYear from 'components/Forms/SelectYear'
import { observer } from 'mobx-react-lite'

const PageResources = observer(() => {
    const year = eventsStore.year;
    useEffect(() => {
        eventsStore.getEventsByYear(year);
    }, [year])

    const events = eventsStore.events;

    return (
        <EventsListCalendarWrapper hideFilters={true} activeSubMenuItem={HeaderSubMenuItemEnum.RESOURCES}>
            <div className='bg-white p-4 rounded-[12px]'>
                <Form onSubmit={() => null} formOptions={{
                    defaultValues: {
                        date: eventsStore.year
                    }
                }} >
                    <SelectYear
                        name='date'
                        className='max-w-[200px]'
                    />
                    <FormFieldWatcher
                        field='date'
                        onChange={(year) => eventsStore.setYear(year)}
                    />
                </Form>

                <div className='mt-4'>
                    <ResourceGrid
                        events={events}
                        year={year}
                    />
                </div>

            </div>
        </EventsListCalendarWrapper >
    )
})

export default PageResources;