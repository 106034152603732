import { eventStatusEnumToLabel } from "helpers/EventStatusHelper";
import { EventStatusEnum } from "types/EventStatusEnum";
import Select from "../Select";

interface Props {
    className?: string,
    name: string,
    placeholder?: string
}

const SelectEventStatus = (props: Props) => {

    return (
        <Select
            label='Status'
            className={props.className || ''}
            name={props.name}
            placeholder={props.placeholder}
            options={[
                {
                    id: EventStatusEnum.ACTIVE.toString(),
                    value: eventStatusEnumToLabel(EventStatusEnum.ACTIVE)
                },
                {
                    id: EventStatusEnum.DRAFT.toString(),
                    value: eventStatusEnumToLabel(EventStatusEnum.DRAFT)
                },
                {
                    id: EventStatusEnum.CANCELLED.toString(),
                    value: eventStatusEnumToLabel(EventStatusEnum.CANCELLED)
                }
            ]}
        />
    )

}

export default SelectEventStatus;