import UserGeneralForm from "components/Forms/UserGeneralForm";
import { UserGeneralFormFieldEnum } from "components/Forms/UserGeneralForm/UserGeneralFormFieldEnum";
import Header from "components/Header";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createUserStore } from "stores/CreateUserStore";

const PageCreateUser = () => {
    const navigate = useNavigate();

    const onSubmit = async (data: { [x in UserGeneralFormFieldEnum]: string; }) => {
        const newUser = await createUserStore.createUser({
            name: data.name,
            email: data.email,
            phone: data.phone,
            title: data.title,
            role: data.role as any
        })

        navigate(generatePath(routes.pageUserDetails, { id: newUser.id }), { replace: true });
        toast('Bruger oprettet. Der er blevet sendt en mail til brugeren med midlertidigt password til login.', { type: 'success' });
    }

    return (
        <div>
            <Header
                onBack={() => navigate(-1)}
            />
            <TwoColumnWrapper
                title='Opret bruger'
                leftColumn={
                    <UserGeneralForm
                        onSubmit={onSubmit}
                        onCancel={() => navigate(-1)}
                        showActionButtonsByDefault={true}
                    />
                }
            />
        </div>
    )

}

export default PageCreateUser;