interface Props {
    children: React.ReactNode,
    className?: string
}

const WhiteBox = (props: Props) => (
    <div
        className={`
            bg-white
            rounded-[20px]
            p-4
            ${props.className || ''}
        `}>
        {props.children}
    </div>
)

export default WhiteBox;