import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import WhiteBox from "components/WhiteBox";
import Header, { ActiveHeaderEnum } from "components/Header";
import HeaderSubMenuItem from "components/Header/HeaderSubMenuItem";
import { useNavigate } from "react-router-dom";
import { routes } from 'components/Routes';
import Input from "components/Forms/Input";
import { eventsStore } from "stores/EventsStore";
import SelectYear from "components/Forms/SelectYear";
import { observer } from "mobx-react-lite";
import FormFieldWatcher from "components/Forms/FormFieldWatcher";
import SelectMonth from "components/Forms/SelectMonth";
import SelectResource from "components/SelectResource";
import SelectEventStatus from "components/Forms/SelectEventStatus";
import { authStore } from "stores/AuthStore";
import { UserRoleEnum } from "types/UserRoleEnum";

export enum HeaderSubMenuItemEnum {
    LIST,
    CALENDAR,
    RESOURCES
}

interface Props {
    children: React.ReactNode,
    hideFilters?: boolean,
    activeSubMenuItem?: HeaderSubMenuItemEnum
}

const EventsListCalendarWrapper = observer((props: Props) => {

    const navigate = useNavigate();

    const onYearChanged = (year: string) => {
        eventsStore.setYear(year);
    }

    const onMonthChanged = (month: string) => {
        eventsStore.setMonth(month);
    }

    const onResourceChanged = (resourceId: string) => {
        eventsStore.setResourceId(resourceId);
    }

    const onStatusChanged = (status: string) => {
        eventsStore.setStatus(status ? parseInt(status) : '');
    }

    const onSearchQueryChanged = (value: string) => {
        eventsStore.setSearchQuery(value);
    }

    return (
        <>
            <Header
                activeMenu={ActiveHeaderEnum.EVENTS}
                subItems={
                    <>
                        <HeaderSubMenuItem
                            label='Liste'
                            onClick={() => navigate(routes.pageFront)}
                            isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.LIST}
                        />
                        <HeaderSubMenuItem
                            label='Kalender'
                            onClick={() => navigate(routes.pageCalendar)}
                            isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.CALENDAR}
                        />
                        <HeaderSubMenuItem
                            label='Ressourcer'
                            onClick={() => navigate(routes.pageResources)}
                            isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.RESOURCES}
                        />
                    </>
                }
            />
            <div className='mx-2 md:mx-12 '>
                <div className='mt-6 md:mt-12 text-xl flex items-center place-content-between'>
                    <h1 className='text-2xl'>
                        Arrangementer
                    </h1>
                    {
                        authStore.user?.role === UserRoleEnum.ADMIN &&
                        <Button
                            className='print:hidden'
                            onClick={() => navigate(routes.pageCreateEvent)}
                            type='button'
                            appearance='primary'
                            label='Nyt arrangement'
                        />
                    }
                </div>
                <div className='mt-8 md:mt-12'>
                    {
                        !props.hideFilters &&
                        <div className='mb-12 print:hidden'>
                            <Form formOptions={{
                                defaultValues: {
                                    year: eventsStore.year,
                                    month: eventsStore.month,
                                    resource: eventsStore.resourceId,
                                    status: eventsStore.status,
                                    searchValue: eventsStore.searchQuery
                                }
                            }} onSubmit={() => null}>
                                <div className='grid gap-4 lg:grid-cols-3'>
                                    <WhiteBox className='flex'>
                                        <FormFieldWatcher field='year' onChange={onYearChanged} />
                                        <SelectYear name='year' className='mx-1' />

                                        <FormFieldWatcher field='month' onChange={onMonthChanged} />
                                        <SelectMonth name='month' className='mx-1' />
                                    </WhiteBox>

                                    <WhiteBox className='flex'>
                                        <FormFieldWatcher field='resource' onChange={onResourceChanged} />
                                        <SelectResource name='resource' className='mx-1' placeholder='Alle' />

                                        <FormFieldWatcher field='status' onChange={onStatusChanged} />
                                        <SelectEventStatus name='status' className='mx-1' placeholder='Alle' />
                                        {/* <Select label='Lokation' name='year' options={[]} className='mx-1' /> */}
                                        {/* <Select label='Status' name='month' options={[]} className='mx-1' /> */}
                                    </WhiteBox>

                                    <WhiteBox className='flex'>
                                        <FormFieldWatcher field='searchValue' onChange={onSearchQueryChanged} />
                                        <Input
                                            label='Søg'
                                            name='searchValue'
                                            className='w-full'
                                            placeholder='Titel eller kunde'
                                        />
                                    </WhiteBox>
                                </div>

                            </Form>
                        </div>
                    }

                    {props.children}
                </div>
            </div>
        </>
    )

})

export default EventsListCalendarWrapper;