import ApiTokensTable from 'components/ApiTokensTable';
import { routes } from 'components/Routes';
import SettingsWrapper, { HeaderSubMenuItemEnum } from 'components/SettingsWrapper'
import { useNavigate } from 'react-router-dom';

const PageSettingsApiTokens = () => {
    const navigate = useNavigate();

    return (
        <SettingsWrapper
            title='API Adgang'
            actionButton={{ label: 'Ny API adgang', onClick: () => navigate(routes.pageCreateApiToken) }}
            activeSubMenuItem={HeaderSubMenuItemEnum.API_TOKENS}>                
                <ApiTokensTable />
                <div className='text-center mt-12'>
                    <a rel="noreferrer" className='border px-4 py-2 rounded bg-gray-50 text-gray-600' href="https://api.teateradmin.dk/swagger/index.html" target="_blank">Gå til API dokumentation</a>
                </div>
        </SettingsWrapper>
    )
}

export default PageSettingsApiTokens;