import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
    field: string,
    onChange: (field: string) => void
}

const FormFieldWatcher = (props: Props) => {
    const { watch } = useFormContext();

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === props.field) {
                props.onChange(value[props.field])
            }
        });
        return () => subscription.unsubscribe();
    }, [props, watch])

    return null;
}

export default FormFieldWatcher;