export enum CustomerGeneralFormFieldEnum {
    NAME = 'name',
    VAT = 'vat',
    CUSTOMER_NUMBER = 'customerNumber',
    ADDRESS = 'address',
    ZIP = 'zip',
    CITY = 'city',
    EAN = 'ean',
    TYPE = 'type',
    PHONE = 'phone',
    EMAIL = 'email',
    WEBSITE = 'website',
    NOTE = 'note'
}