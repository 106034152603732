import { userRoleEnumToLabel } from "helpers/UserRoleHelper";
import { UserRoleEnum } from "types/UserRoleEnum";
import Select from "../Select";

interface Props {
    className?: string,
    name: string,
    placeholder?: string
}

const SelectUserRole = (props: Props) => {

    return (
        <Select
            label='Rettigheder'
            className={props.className || ''}
            name={props.name}
            placeholder={props.placeholder}
            options={[
                {
                    id: UserRoleEnum.ADMIN.toString(),
                    value: userRoleEnumToLabel(UserRoleEnum.ADMIN)
                },
                {
                    id: UserRoleEnum.USER.toString(),
                    value: userRoleEnumToLabel(UserRoleEnum.USER)
                }
            ]}
        />
    )

}

export default SelectUserRole;