import { routes } from "components/Routes";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ReactNode, useEffect, useRef } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { resourceStore } from "stores/ResourceStore";
import { EventListInterface } from 'types/EventListInterface';
import { ResourceListInterface } from 'types/ResourceListInterface';
import { Theme, Tooltip, withStyles } from "@material-ui/core";
import css from './style.module.scss';
import { EventStatusEnum } from 'types/EventStatusEnum';

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#173445', // office club dark blue
        color: '#fff',
        maxWidth: 360,
        fontSize: theme.typography.pxToRem(12),
    },
    arrow: {
        color: '#173445', // office club dark blue
    },
}))(Tooltip);


interface Props {
    events: Array<EventListInterface>,
    year: string
}

const ResourceGrid = observer((props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        resourceStore.getResources();
        ref.current?.focus();

        return () => {
            resourceStore.resetStore();
        }
    }, []);

    const renderDates = () => {
        const iterationDate = moment().year(Number(props.year)).startOf('year');
        const lastDateOfYear = moment().year(Number(props.year)).endOf('year');

        const resultArr: Array<ReactNode> = [];

        let dayOfYear = 1;
        while (iterationDate.isSameOrBefore(lastDateOfYear)) {
            resultArr.push(
                <th
                    className={`text-sm text-white bg-light-red ${iterationDate.month() % 2 === 0 ? '' : 'opacity-80'}`}
                    key={dayOfYear}>
                    {iterationDate.date()}
                </th>
            )

            dayOfYear++;
            iterationDate.add(1, 'day');
        }

        return resultArr;
    }

    const renderMonthTitles = () => {
        const iterationDate = moment().year(Number(props.year)).startOf('year');
        const lastDateOfYear = moment().year(Number(props.year)).endOf('year');

        const resultArr: Array<ReactNode> = [];

        let monthNo = 1;
        while (iterationDate.isSameOrBefore(lastDateOfYear)) {
            resultArr.push(
                <th
                    className={`text-sm text-white ${iterationDate.month() % 2 === 0 ? 'bg-dark-red' : 'bg-light-red'}`}
                    colSpan={iterationDate.daysInMonth()}
                    key={monthNo}>
                    {iterationDate.format('MMMM')}
                </th>
            )

            monthNo++;
            iterationDate.add(1, 'month');
        }

        return resultArr;
    }

    const renderAvailability = (events: Array<EventListInterface>, resource: ResourceListInterface, bgClass: string) => {
        const iterationDate = moment().year(Number(props.year)).startOf('year');
        const lastDateOfYear = moment().year(Number(props.year)).endOf('year');

        const resultArr: Array<ReactNode> = [];

        const resourceEvents = _.filter(events, e => e.location.id === resource.id);

        const mappedEvents = resourceEvents.map((e) => {
            return {
                title: e.title,
                status: e.status,
                eventId: e.id,
                from: moment(e.from),
                to: moment(e.to)
            }
        })

        let dayOfYear = 1;
        while (iterationDate.isSameOrBefore(lastDateOfYear)) {
            const eventsSameDay = _.filter(mappedEvents, e => {
                return (e.from.isSameOrBefore(iterationDate, 'date') && e.to.isSameOrAfter(iterationDate, 'date'))
            })
            const isBusy = eventsSameDay.length > 0;

            // If any events are confirmed, background should be red
            // If nothing confirmed, the event is either tentative or cancelled - Color them yellow
            const busyBackground = _.some(eventsSameDay, s => s.status === EventStatusEnum.ACTIVE) ? 'bg-light-red' : 'bg-yellow-600';

            const elementNode = (
                <td
                    onClick={isBusy ? () => navigate(generatePath(routes.pageEventDetails, { id: eventsSameDay[0].eventId })) : undefined}
                    key={dayOfYear}
                    className={`
                        ${isBusy ? busyBackground : bgClass}
                        ${isBusy && 'cursor-pointer'}
                `}>
                </td>
            )

            if (isBusy) {
                resultArr.push(
                    <HtmlTooltip
                        key={dayOfYear}
                        arrow
                        placement='top'
                        title={eventsSameDay.map(e => e.title).join(', ')}>
                            {elementNode}
                    </HtmlTooltip>
                )
            }
            else {
                resultArr.push(
                    elementNode
                )
            }

            dayOfYear++;
            iterationDate.add(1, 'day');
        }

        return resultArr;
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (ref.current) {
            if (e.key === 'ArrowRight') {
                ref.current.scrollLeft += ref.current.offsetWidth - 300;
            }
            else if (e.key === 'ArrowLeft') {
                ref.current.scrollLeft -= ref.current.offsetWidth - 300;
            }
        }
    }

    const arrowValue = (index: number) => {
        if (ref.current) {
            if (index > 0) {
                ref.current.scrollLeft += ref.current.offsetWidth - 300;
            }
            else {
                ref.current.scrollLeft -= ref.current.offsetWidth - 300;
            }
        }
    }

    let resources = resourceStore.resources;
    const events = props.events;

    return (
        <div>
            <div className='flex justify-end -mt-14'>
                <div className='flex gap-2 pb-4'>
                    <button className='border px-2 py-1 bg-gray-200 rounded' type="button" onClick={() => arrowValue(-1)}>&larr;</button>
                    <button className='border px-2 py-1 bg-gray-200 rounded' type="button" onClick={() => arrowValue(1)}>&rarr;</button>
                </div>
            </div>
            <div className='overflow-x-auto' onKeyDown={onKeyDown} ref={ref} tabIndex={0}>
                <table className={css.resourceTable}>
                    <thead>
                        <tr>
                            <th className='bg-dark-red' colSpan={1}></th>
                            {renderMonthTitles()}
                        </tr>
                        <tr>
                            <th className='bg-dark-red'></th>
                            {renderDates()}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            resources.map((r, i) => {
                                const resultArr: Array<ReactNode> = [];

                                const bgClass = i % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200';

                                resultArr.push(<th key={r.id + i} className={bgClass}>{r.name}</th>);

                                return (
                                    <tr key={r.id}>
                                        {[...resultArr, ...renderAvailability(events, r, bgClass)]}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>

    )
})

export default ResourceGrid;