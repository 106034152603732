import Button from "components/Forms/Button";
import { useEffect, useRef, useState } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

interface Item {
    label: string,
    onClick?: () => void
}

interface Props {
    label: string,
    items: Array<Item>
}

const ExpandButton = (props: Props) => {
    const myRef = useRef<any>();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClickOutside = (e: any) => {
        if (!myRef.current?.contains(e.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className='relative print:hidden' onClick={() => setIsExpanded(!isExpanded)}>
            <div className='cursor-pointer select-none'>
                <Button
                    type='button'
                    appearance='secondary'
                    label={props.label}
                    icon={faCaretDown}
                />
            </div>
            {
                isExpanded &&
                <div
                    ref={myRef}
                    className='
                        absolute
                        z-50
                        bg-white
                        min-w-[200px]
                        right-0
                        text-sm
                        text-right
                        p-2
                        rounded
                        shadow-lg
                    '
                    // onMouseLeave={() => setIsExpanded(false)}
                    >
                    <ul>
                        {
                            props.items.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={item.onClick}
                                    className='my-4 cursor-pointer font-normal'>
                                    {item.label}
                                </li>
                            ))
                        }
                    </ul>
                </div>
            }
        </div>
    )

}

export default ExpandButton;