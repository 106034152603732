import ContactGeneralForm from "components/Forms/ContactGeneralForm";
import { ContactGeneralFormFieldEnum } from "components/Forms/ContactGeneralForm/ContactGeneralFormFieldEnum";
import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { customerDetailsStore } from "stores/CustomerDetailsStore";
import { CreateContactInterface } from "types/CreateContactInterface";

interface Props {
    customerId: string
}

const CreateContact = (props: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onSubmit = async (data: { [x in ContactGeneralFormFieldEnum]: string }) => {
        const contact = {
            name: data.name,
            title: data.title,
            phone: data.phone,
            mobile: data.mobile,
            email: data.email,
        } as CreateContactInterface;

        await customerDetailsStore.createContact(props.customerId, contact);
        setIsExpanded(false)
    }

    return (
        <>
            {
                !isExpanded &&
                <div onClick={() => setIsExpanded(true)}>
                    <WhiteBox className='mb-4 p-6 opacity-30 hover:opacity-80 cursor-pointer'>
                        <div className='flex items-center place-content-between'>
                            <h1 className='text-xl'>
                                Ny kontaktperson
                            </h1>
                        </div>
                    </WhiteBox>
                </div>
            }

            {
                isExpanded &&
                <ContactGeneralForm
                    showActionButtonsByDefault={true}
                    onCancel={() => setIsExpanded(false)}
                    onSubmit={onSubmit}
                />
            }
        </>
    )
}

export default CreateContact;