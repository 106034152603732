import { action, makeObservable, observable } from "mobx";
import { CreateCustomerInterface } from "types/CreateCustomerInterface";
import { CustomerListInterface } from "types/CustomerListInterface";
import { BaseStore } from "./BaseStore";

class CreateCustomerStore extends BaseStore {

    customer: CustomerListInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            customer: observable,
            resetStore: action,
            createCustomer: action
        })
    }

    resetStore = () => {
        this.customer = null;
    }

    createCustomer = (customer: CreateCustomerInterface): Promise<CustomerListInterface> => {
        return this.post(`customers`, customer, 'customer');
    }

}

export const createCustomerStore = new CreateCustomerStore();