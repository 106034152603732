import EventDetailsHeader from 'components/EventDetailsHeader';
import ExpandButton from "components/ExpandButton";
import EventGeneralForm from "components/Forms/EventGeneralForm";
import { EventGeneralFormFieldEnum } from "components/Forms/EventGeneralForm/EventGeneralFormFieldEnum";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import { exportToLessorFormattedCsv } from "helpers/CsvHelper";
import _ from 'lodash';
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authStore } from "stores/AuthStore";
import { eventDetailsStore } from "stores/EventDetailsStore";
import { notifyRecipientsModalStore } from "stores/NotifyRecipientsModalStore";
import { resourceStore } from "stores/ResourceStore";
import { EventListInterface } from "types/EventListInterface";
import { EventStatusEnum } from "types/EventStatusEnum";
import { UserRoleEnum } from "types/UserRoleEnum";
import CreateSection from "./CreateSection";
import EventFiles from "./EventFiles";
import Section from "./Section";
import { eventsStore } from 'stores/EventsStore';

const PageEventDetails = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            eventDetailsStore.getEvent(id);
            eventDetailsStore.getSections(id);
        }
    }, [id])

    useEffect(() => {
        return () => {
            eventDetailsStore.resetStore();
        }
    }, [])

    const onDragEnd = async (result: DropResult) => {
        const sections = _.clone(eventDetailsStore.sections);

        const element = sections.splice(result.source.index, 1)[0];
        sections.splice(result.destination!.index, 0, element);

        await eventDetailsStore.putSections(id!, sections.map((s) => ({
            id: s.id
        })))
    }

    const onDeleteEvent = async () => {
        const event = eventDetailsStore.event;
        let deleteMsg = 'Er du helt sikker på at du vil slette dette arrangement?';

        if (event?.originalId) {
            deleteMsg += '\n\nOBS: DETTE ARRANGEMENT ER ALLEREDE OPRETTET I LESSOR. HUSK AT DU EFTERFØLGENDE SELV SKAL SLETTE DET I LESSOR.';
        }

        if (!window.confirm(deleteMsg)) return;
        await eventDetailsStore.deleteEvent(id!);
        navigate(routes.pageFront);
    }

    const onSaveGeneral = async (data: { [x in EventGeneralFormFieldEnum]: any; }) => {
        const eventBeforeSave = eventDetailsStore.event;
        if (!eventBeforeSave) throw Error('Event mangler');

        const fromIso = moment(data.startDate).toISOString();
        const toIso = moment(data.endDate).toISOString();

        const [availability] = await resourceStore.getAvailability(data.resource, [{
            from: fromIso,
            to: toIso
        }]);

        if (availability.conflictEvent && availability.conflictEvent.id !== id) {
            toast('Lokalet er desværre allerede optaget i det ønskede tidsrum', { type: 'error' });
            return;
        }

        const newData = {
            id: id,
            customer: data.customer,
            description: data.description,
            from: fromIso,
            to: toIso,
            location: { id: data.resource },
            title: data.title,
            status: data.status as any,
            responsible: eventDetailsStore.event?.responsible,
            contact: data.contact,
            originalId: eventBeforeSave.originalId
        } as EventListInterface;

        await eventDetailsStore.updateEvent(newData) as EventListInterface;

        if (Number(data.status) === EventStatusEnum.ACTIVE) {
            // Event has been synchronized before
            if (eventBeforeSave?.originalId) {
                const dateTimeDiff = moment(eventBeforeSave.from).toISOString() !== fromIso || moment(eventBeforeSave.to).toISOString() !== toIso;
                const titleDiff = newData.title !== eventBeforeSave.title;
                const customerDiff = newData.customer?.id !== eventBeforeSave.customer?.id;
                const descriptionDiff = newData.description !== eventBeforeSave.description;
                const locationDiff = newData.location.id !== eventBeforeSave.location.id;

                if (
                    dateTimeDiff
                    || titleDiff
                    || customerDiff
                    || descriptionDiff
                    || locationDiff
                ) {
                    try {
                        await eventDetailsStore.sendToLessor(eventBeforeSave);
                        toast('Ændringer sendt til Lessor', { type: 'success' });
                    }
                    catch (err) {
                        toast('Der opstod en fejl ved synkronisering med Lessor', { type: 'error' });
                    }
                }
            }
            else {
                // Event hasn't been synchronized to Lessor, and state is active
                // We should sync.
                try {
                    await eventDetailsStore.sendToLessor(eventBeforeSave);
                    toast('Arrangementet er oprettet i Lessor', { type: 'success' });
                }
                catch (err) {
                    toast('Der opstod en fejl ved oprettelse i Lessor', { type: 'error' });
                }
            }
        }

        if (Number(data.status) !== EventStatusEnum.ACTIVE && !!eventBeforeSave?.originalId) {
            alert('Husk selv at slette arrangementet fra Lessor! Det kan desværre ikke gøres automatisk.');
        }

        notifyRecipientsModalStore.showModal(id!);
    }

    const exportAsCSV = () => {
        const event = eventDetailsStore.event;
        if (!event) {
            toast('Der opstod en fejl', { type: 'error' });
            return;
        }

        const lessorId = window.prompt('Er arrangementet allerede oprettet i Lessor? I så fald, bedes du indtaste ID fra Lessor her. Ellers tryk blot OK uden at udfylde.');

        exportToLessorFormattedCsv('event.csv', event, lessorId ? Number(lessorId) : undefined);
    }

    const exportDocx = async (eventId: string) => {
        const token = await authStore.getDownloadToken();
        window.open(`${process.env.REACT_APP_API_BASE_URL}/events/${eventId}/docx?token=${token}`);
    }

    const headerActions = () => {
        const items = [
            {
                label: 'Udskriv',
                onClick: () => window.print()
            },
            {
                label: 'Eksportér (CSV)',
                onClick: () => exportAsCSV()
            },
        ]

        if (authStore.user?.role === UserRoleEnum.ADMIN) {
            // Add first in array
            items.unshift({
                label: 'Duplikér',
                onClick: () => navigate(generatePath(routes.pageDuplicateEvent, { id: id }))
            })

            items.push({
                label: 'Opret bekræftelse (Docx)',
                onClick: () => exportDocx(id!)
            });

            items.push(
                {
                    label: 'Underret',
                    onClick: () => notifyRecipientsModalStore.showModal(id!)
                }
            )

            // Add last
            items.push(
                {
                    label: 'Slet arrangement',
                    onClick: () => onDeleteEvent()
                },
            )
        }

        return (
            <div className="print:hidden flex items-center gap-2">
            <div className='flex gap-2'>
                <button className='border px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded' type="button" onClick={() => gotoEvent('previous')}>&larr;</button>
                <button className='border px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded' type="button" onClick={() => gotoEvent('next')}>&rarr;</button>
            </div>
            <ExpandButton
                label='Handlinger'
                items={items}
            />
            </div>
        )
    }

    const gotoEvent = async (string: 'next' | 'previous') => {
        let eventList = _.orderBy(eventsStore.events, ev => ev.from);
        
        if (eventList.length === 0) {
            eventsStore.setYear(moment(event?.from).year().toString(), false);
            eventsStore.setMonth(moment(event?.from).month().toString(), false);
            eventList = await eventsStore.getEvents();
        }
        
        const index = eventList.findIndex((e: EventListInterface) => e.id === id);
        if (index === -1) return;
        
        const nextEvent = string === 'next' ? eventList[index + 1] : eventList[index - 1];
        if (!nextEvent) return;

        navigate(generatePath(routes.pageEventDetails, { id: nextEvent.id }));
    }

    const event = eventDetailsStore.event;
    if (!event) return null;

    const sections = eventDetailsStore.sections;

    return (
        <div>
            <EventDetailsHeader eventId={id!} />
            <TwoColumnWrapper
                title={`Arrangement ${event.originalId ? `(LessorID: ${event.originalId})` : ''}`}
                topRight={headerActions()}
                leftColumn={
                    <div>
                        <EventGeneralForm
                            defaultValues={{
                                [EventGeneralFormFieldEnum.DESCRIPTION]: event.description,
                                [EventGeneralFormFieldEnum.CUSTOMER]: event.customer,
                                [EventGeneralFormFieldEnum.START_DATE]: new Date(event.from),
                                [EventGeneralFormFieldEnum.END_DATE]: new Date(event.to),
                                [EventGeneralFormFieldEnum.STATUS]: event.status,
                                [EventGeneralFormFieldEnum.TITLE]: event.title,
                                [EventGeneralFormFieldEnum.RESOURCE]: event.location?.id,
                                [EventGeneralFormFieldEnum.CONTACT_PERSON]: event.contact
                            }}
                            onSubmit={onSaveGeneral}
                        />

                        {
                            event && (
                                <div className='hidden print:block'>
                                    <ul>
                                        <li><b>{event.title}, {event.location?.name}</b>, {moment(event.from).format('DD/MM/YY HH:mm')} - {moment(event.to).format('DD/MM/YY HH:mm')}</li>
                                        <li className='text-sm'>{event.customer?.name}</li>
                                        <li className='pt-4'>{event.description}</li>
                                    </ul>
                                </div>
                            )
                        }

                        <EventFiles eventId={id!} />
                    </div>
                }
                rightColumn={
                    <div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {
                                // Forces rerender of child context
                            }
                            <div style={{ display: 'none' }}>
                                {sections.length}
                            </div>
                            <Droppable droppableId='list'>
                                {
                                    provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <div>
                                                {
                                                    sections.map((section, index) => {
                                                        return (
                                                            <Section
                                                                key={section.id}
                                                                index={index}
                                                                section={section}
                                                                event={event}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>

                                            {provided.placeholder}
                                        </div>
                                    )
                                }

                            </Droppable>
                        </DragDropContext>
                        {
                            authStore.user?.role === UserRoleEnum.ADMIN &&
                            <CreateSection event={event} />
                        }
                    </div>
                }
            />
            {/* <PrintEventFormat
                event={event}
                sections={sections}
            /> */}
        </div>
    )
})

export default PageEventDetails;