import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Textarea from "components/Forms/Textarea";
import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { toast } from "react-toastify";
import { eventDetailsStore } from "stores/EventDetailsStore";
import { notifyRecipientsModalStore } from "stores/NotifyRecipientsModalStore";
import { EventListInterface } from "types/EventListInterface";
import { YupInterface } from "types/YupInterface";
import ResetFormButton from "../Section/ResetFormButton";
import { CreateSectionFormFieldEnum } from "./CreateSectionFormFieldEnum";

interface Props {
    event: EventListInterface
}

const CreateSection = (props: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onSubmit = async (data: { [x in CreateSectionFormFieldEnum]: string }) => {
        const section = await eventDetailsStore.createSection(props.event.id, {
            title: data.title,
            body: data.body
        })

        notifyRecipientsModalStore.showModal(props.event.id, section.id);

        try {
            await eventDetailsStore.sendToLessor(props.event);
        }
        catch (err) {
            toast('Der opstod en fejl ved synkronisering med Lessor', { type: 'error' });
        }

        setIsExpanded(false);
    }

    const validation = (yup: YupInterface) => {
        return {
            [CreateSectionFormFieldEnum.TITLE]: yup.string().required('Påkrævet'),
            [CreateSectionFormFieldEnum.BODY]: yup.string().required('Påkrævet')
        }
    }

    return (
        <>
            {
                !isExpanded &&
                <div onClick={() => setIsExpanded(true)}>
                    <WhiteBox className='mb-4 p-6 opacity-30 hover:opacity-80 cursor-pointer print:hidden'>
                        <div className='flex items-center place-content-between'>
                            <h1 className='text-xl'>
                                Opret ny sektion
                            </h1>
                        </div>
                    </WhiteBox>
                </div>
            }

            {
                isExpanded &&
                <WhiteBox className='mb-4 p-6 relative'>
                    <Form onSubmit={onSubmit} validation={validation}>
                        <div className='flex items-center place-content-between mb-8'>
                            <h1 className='text-xl w-full'>
                                <Input
                                    name={CreateSectionFormFieldEnum.TITLE}
                                    className="mb-0"
                                    placeholder='Titel'
                                />
                            </h1>
                        </div>
                        <Textarea
                            name={CreateSectionFormFieldEnum.BODY}
                            placeholder='Tekst...'
                        />
                        <div className='text-right mt-4'>
                            <ResetFormButton afterReset={() => setIsExpanded(false)} />
                            <Button
                                type='submit'
                                label='Opret'
                                appearance='primary'
                            />
                        </div>
                    </Form>
                </WhiteBox>
            }
        </>
    )

}

export default CreateSection;