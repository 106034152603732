import Label from "components/Forms/Label";
import { Controller, useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import AsyncCreatableSelect from 'react-select/async-creatable';
import Error from "components/Forms/Error";
import { OptionsOrGroups } from "react-select";

interface Props extends FormElementInterface {
    onInputChange?: (newValue: string) => void,
    loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<any, any>) => void) => void,
    onCreate: (value: string) => void
}

const SelectAsync = (props: Props) => {
    const { control } = useFormContext();

    return (
        <div>
            <Controller
                name={props.name}
                control={control}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => (
                    <div className='w-full async-select'>
                        <Label name={props.name} label={props.label} />
                        <AsyncCreatableSelect
                            onCreateOption={props.onCreate}
                            onChange={onChange}
                            onInputChange={props.onInputChange}
                            isClearable={true}
                            loadOptions={props.loadOptions}
                            value={value}
                            loadingMessage={(val) => 'Øjeblik...'}
                            noOptionsMessage={(input) => 'Ingen resultater'}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            placeholder={props.placeholder}
                            formatOptionLabel={option => option.__isNew__ ? `Opret ny` : option.name}
                            styles={{
                                control: base => ({
                                    ...base,
                                    height: '40px',
                                    borderRadius: '0.25rem',
                                    borderColor: '#e5e7eb'
                                })
                            }}
                        />
                        <Error name={props.name} />
                    </div>
                )} />
        </div>
    )
}

export default SelectAsync;