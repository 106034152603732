import Table from 'components/Table';
import Cell from 'components/Table/Cell';
import { userRoleEnumToLabel } from 'helpers/UserRoleHelper';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { apiTokensStore } from 'stores/ApiTokensStore';
import { ApiTokenInterface } from 'types/ApiTokenInterface';

const ApiTokensTable = observer(() => {

    useEffect(() => {
        apiTokensStore.getTokens();

        return () => {
            apiTokensStore.resetStore();
        }
    }, [])

    const onDelete = async (token: ApiTokenInterface) => {
        if (!window.confirm('Er du sikker på du vil slette denne adgang?')) return;

        await apiTokensStore.deleteToken(token.id);
        apiTokensStore.getTokens();
        toast.success('Adgang slettet');
    }

    const tokens = apiTokensStore.tokens;

    return (
        <Table
            data={tokens}
            defaultSortProperty='tokenName'
            defaultSortDirection='asc'
            head={[
                {
                    label: 'Virksomhed / Platform',
                    sortProperty: 'tokenName'
                },
                {
                    label: 'Ansvarlig',
                    sortProperty: 'userName'
                },
                {
                    label: 'Rettigheder',
                    sortProperty: 'role',
                    rowFormat: (row: ApiTokenInterface) => userRoleEnumToLabel(row.role)
                },
                {
                    label: 'Token udløber',
                    sortProperty: 'expiresAt',
                    rowFormat: (row: ApiTokenInterface) => moment(row.expiresAt).format('DD/MM/YYYY')
                },
                {
                    label: 'Handling',
                    sortProperty: 'Handling',
                }
            ]}
            renderRow={(token: ApiTokenInterface) => {
                return (
                    <>
                        <Cell>{token.name}</Cell>
                        <Cell>{token.internalUser?.name}</Cell>
                        <Cell>{userRoleEnumToLabel(token.role)}</Cell>
                        <Cell>{moment(token.expiresAt).format('DD/MM/YYYY')}</Cell>
                        <Cell><button className='border px-2 py-1 text-xs rounded' type="button" onClick={() => onDelete(token)}>Slet adgang</button></Cell>
                    </>                    
                )
            }}
        />
    )
})

export default ApiTokensTable;