import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Textarea from "components/Forms/Textarea";
import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { Draggable } from 'react-beautiful-dnd';
import { eventDetailsStore } from "stores/EventDetailsStore";
import { EventSectionInterface } from "types/EventSectionInterface";
import { SectionFormFieldEnum } from "./SectionFormFieldEnum";
import _ from 'lodash';
import ResetFormButton from "./ResetFormButton";
import ExpandButton from "components/ExpandButton";
import { notifyRecipientsModalStore } from "stores/NotifyRecipientsModalStore";
import { YupInterface } from "types/YupInterface";
import { authStore } from "stores/AuthStore";
import { UserRoleEnum } from "types/UserRoleEnum";
import { EventListInterface } from "types/EventListInterface";
import { toast } from "react-toastify";

interface Props {
    index: number,
    section: EventSectionInterface
    event: EventListInterface
}

const Section = (props: Props) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const onSubmit = async (data: { [x in SectionFormFieldEnum]: string; }) => {
        const section = _.clone(props.section);

        section.title = data.title;
        section.body = data.body;

        await eventDetailsStore.updateSection(section.eventId, section);
        notifyRecipientsModalStore.showModal(section.eventId, section.id);

        try {
            await eventDetailsStore.sendToLessor(props.event);
        }
        catch (err) {
            toast('Der opstod en fejl ved synkronisering med Lessor', { type: 'error' });
        }

        setIsEditMode(false);
    }

    const onDelete = async () => {
        if (!window.confirm('Er du sikker på at du vil slette denne sektion?')) return;
        await eventDetailsStore.deleteSection(props.section.eventId, props.section.id);

        try {
            await eventDetailsStore.sendToLessor(props.event);
        }
        catch (err) {
            toast('Der opstod en fejl ved synkronisering med Lessor', { type: 'error' });
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            [SectionFormFieldEnum.TITLE]: yup.string().required('Påkrævet'),
            [SectionFormFieldEnum.BODY]: yup.string().required('Påkrævet')
        }
    }

    return (
        <Draggable draggableId={props.section.id} index={props.index}>
            {
                provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <WhiteBox className='mb-4 p-6 relative'>
                            <Form 
                                validation={validation}
                                formOptions={{defaultValues: {
                                    [SectionFormFieldEnum.TITLE]: props.section.title,
                                    [SectionFormFieldEnum.BODY]: props.section.body
                                }}}
                                onSubmit={onSubmit}>
                                {
                                    (!isEditMode && authStore.user?.role === UserRoleEnum.ADMIN) &&
                                    <div className='w-full h-4 absolute top-0 left-0' {...provided.dragHandleProps} />
                                }

                                <div className='flex items-center place-content-between mb-8'>
                                    <h1 className='text-xl w-full pr-12'>
                                        {!isEditMode && props.section.title}
                                        {isEditMode && <Input name={SectionFormFieldEnum.TITLE} className="mb-0" />}
                                    </h1>
                                    <div>
                                        {
                                            (!isEditMode && authStore.user?.role === UserRoleEnum.ADMIN) &&
                                            <ExpandButton
                                                label=''
                                                items={[
                                                    {
                                                        label: 'Redigér',
                                                        onClick: () => setIsEditMode(!isEditMode)
                                                    },
                                                    {
                                                        label: 'Slet',
                                                        onClick: () => onDelete()
                                                    }
                                                ]}
                                            />
                                        }
                                    </div>
                                </div>
                                {
                                    !isEditMode &&
                                    <p className='whitespace-pre-line'>
                                        {props.section.body}
                                    </p>
                                }

                                {
                                    isEditMode &&
                                    <>
                                        <Textarea
                                            name={SectionFormFieldEnum.BODY}
                                        />
                                        <div className='text-right mt-4'>
                                            <ResetFormButton afterReset={() => setIsEditMode(false)} />
                                            <Button
                                                type='submit'
                                                label='Gem'
                                                appearance='primary'
                                            />
                                        </div>
                                    </>
                                }

                            </Form>
                        </WhiteBox>
                    </div>
                )
            }

        </Draggable>
    )
}

export default Section;