import Button from "components/Forms/Button";
import Header, { ActiveHeaderEnum } from "components/Header";
import HeaderSubMenuItem from "components/Header/HeaderSubMenuItem";
import { routes } from "components/Routes";
import { useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { UserRoleEnum } from "types/UserRoleEnum";

export enum HeaderSubMenuItemEnum {
    CUSTOMERS,
    RESOURCES,
    USERS,
    CHANGE_PASSWORD,
    API_TOKENS
}

interface Props {
    children: React.ReactNode,
    activeSubMenuItem?: HeaderSubMenuItemEnum,
    title?: string,
    actionButton?: {
        label: string,
        onClick: () => void
    }
}

const SettingsWrapper = (props: Props) => {

    const navigate = useNavigate();

    return (
        <>
            <Header
                activeMenu={ActiveHeaderEnum.SETTINGS}
                subItems={
                    <>
                        {
                            authStore.user?.role === UserRoleEnum.ADMIN &&
                            <>
                                <HeaderSubMenuItem
                                    label='Kunder'
                                    onClick={() => navigate(routes.pageSettingsCustomers)}
                                    isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.CUSTOMERS}
                                />
                                <HeaderSubMenuItem
                                    label='Lokaler'
                                    onClick={() => navigate(routes.pageSettingsResources)}
                                    isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.RESOURCES}
                                />
                                <HeaderSubMenuItem
                                    label='Brugere'
                                    onClick={() => navigate(routes.pageSettingsUsers)}
                                    isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.USERS}
                                />
                                <HeaderSubMenuItem
                                    label='API'
                                    onClick={() => navigate(routes.pageSettingsApiTokens)}
                                    isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.API_TOKENS}
                                />
                            </>
                        }

                        <HeaderSubMenuItem
                            style={{ borderLeft: '1px solid white', paddingLeft: '30px' }}
                            label='Skift password'
                            onClick={() => navigate(routes.pageSettingsChangePassword)}
                            isActive={props.activeSubMenuItem === HeaderSubMenuItemEnum.CHANGE_PASSWORD}
                        />
                    </>
                }
            />
            <div className='mx-2 md:mx-12 '>
                {
                    (props.title || props.actionButton) &&
                    <div className='mt-6 md:mt-12 text-xl flex items-center place-content-between'>
                        {
                            props.title &&
                            <h1 className='text-2xl'>
                                {props.title}
                            </h1>
                        }

                        {
                            props.actionButton &&
                            <Button
                                type='button'
                                appearance='primary'
                                label={props.actionButton.label}
                                onClick={() => props.actionButton!.onClick()}
                            />
                        }
                    </div>
                }
                <div className='mt-8 md:mt-12'>
                    {props.children}
                </div>
            </div>
        </>
    )

}

export default SettingsWrapper;