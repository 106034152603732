import request from "helpers/Request";
import { StatusCodes } from "http-status-codes";
import { action, makeObservable, observable } from "mobx";
import { CreateEventSectionInterface } from "types/CreateEventSectionInterface";
import { EventListInterface } from "types/EventListInterface";
import { EventSectionInterface } from "types/EventSectionInterface";
import FileInterface from "types/FileInterface";
import { BaseStore } from "./BaseStore";

class EventDetailsStore extends BaseStore {

    event: EventListInterface | null = null;
    sections: Array<EventSectionInterface> = [];
    files: Array<FileInterface> = [];

    constructor() {
        super()

        makeObservable(this, {
            event: observable,
            sections: observable,
            files: observable,

            resetStore: action,
            getEvent: action,
            updateEvent: action,
            deleteEvent: action,
            sendToLessor: action,
            getSections: action,
            createSection: action,
            updateSection: action,
            deleteSection: action,
            putSections: action,
            getFiles: action,
            addFile: action,
            deleteFile: action
        })
    }

    resetStore = () => {
        this.event = null;
        this.sections = [];
    }

    getEvent = (id: string) => {
        return this.get(`events/${id}`, 'event');
    }

    updateEvent = (event: EventListInterface) => {
        return this.put(`events/${event.id}`, event, 'event');
    }

    deleteEvent = (id: string) => {
        return this.delete(`events/${id}`, id, this.event);
    }

    sendToLessor = (event: EventListInterface) => {
        return this.post(`events/${event.id}/send-to-lessor`, event, this.event, true);
    }

    duplicateEvent = (eventId: string, isoDates: Array<string>) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`events/${eventId}/duplicate`, {
                    fromDates: isoDates,
                    duplicateFiles: true,
                    duplicateSections: true
                })
                if (res.status === StatusCodes.OK) {
                    resolve(res.data);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    getSections = (eventId: string) => {
        return this.get(`events/${eventId}/sections`, 'sections');
    }

    createSection = (eventId: string, section: CreateEventSectionInterface): Promise<EventSectionInterface> => {
        return this.post(`events/${eventId}/sections`, section, this.sections, true);
    }

    updateSection = (eventId: string, section: EventSectionInterface) => {
        return this.put(`events/${eventId}/sections/${section.id}`, section, 'sections');
    }

    deleteSection = (eventId: string, sectionId: string) => {
        return this.delete(`events/${eventId}/sections/${sectionId}`, sectionId, this.sections);
    }

    putSections = (eventId: string, order: Array<{ id: string }>) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.put(`events/${eventId}/sections/sort`, {
                    sections: order
                });
                if (res.status === StatusCodes.OK) {
                    this.sections = res.data;
                    resolve(res.data)
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    getFiles = (eventId: string) => {
        return this.get(`events/${eventId}/files`, 'files');
    }

    addFile = (eventId: string, fileId: string) => {
        return this.post(`events/${eventId}/files`, { id: fileId }, this.files, true);
    }

    deleteFile = (eventId: string, fileId: string) => {
        return this.delete(`events/${eventId}/files/${fileId}`, fileId, this.files);
    }

}

export const eventDetailsStore = new EventDetailsStore();