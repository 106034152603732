import EventsListCalendarWrapper, { HeaderSubMenuItemEnum } from "components/EventsListCalendarWrapper";
import EventsTable from "components/EventsTable";

const PageFront = () => {
    return (
        <EventsListCalendarWrapper activeSubMenuItem={HeaderSubMenuItemEnum.LIST}>
            <EventsTable />
        </EventsListCalendarWrapper>
    )
}

export default PageFront;