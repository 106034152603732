import TwoColumnWrapper from "components/TwoColumnWrapper";
import EventGeneralForm from "components/Forms/EventGeneralForm";
import { EventGeneralFormFieldEnum } from "components/Forms/EventGeneralForm/EventGeneralFormFieldEnum";
import Header from "components/Header";
import { generatePath, useNavigate } from "react-router-dom";
import { createEventStore } from "stores/CreateEventStore";
import { routes } from 'components/Routes';
import moment from 'moment';
import { authStore } from "stores/AuthStore";
import { resourceStore } from "stores/ResourceStore";
import { toast } from "react-toastify";
import { EventStatusEnum } from "types/EventStatusEnum";
import { useEffect } from "react";
import { eventDetailsStore } from "stores/EventDetailsStore";

const PageCreateEvent = () => {

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            createEventStore.resetStore();
        }
    }, [])

    const onSubmit = async (data: { [x in EventGeneralFormFieldEnum]: any; }) => {
        const fromIso = moment(data.startDate).toISOString();
        const toIso = moment(data.endDate).toISOString();

        const [availability] = await resourceStore.getAvailability(data.resource, [{
            from: fromIso,
            to: toIso
        }]);

        if (availability.conflictEvent) {
            toast('Lokalet er desværre allerede optaget i det ønskede tidsrum', { type: 'error' });
            return;
        }

        const newEvent = await createEventStore.createEvent({
            customer: data.customer,
            description: data.description,
            from: fromIso,
            to: toIso,
            location: { id: data.resource },
            title: data.title,
            status: data.status as any,
            contact: data.contact,
            responsible: {
                id: authStore.user!.id
            }
        });

        if (Number(data.status) === EventStatusEnum.ACTIVE) {
            try {
                await eventDetailsStore.sendToLessor(newEvent);
                toast('Arrangementet er oprettet i Lessor', { type: 'success' });
            }
            catch (err) {
                toast('Der opstod en fejl ved oprettelse i Lessor', { type: 'error' });
            }
        }

        navigate(generatePath(routes.pageEventDetails, { id: newEvent.id }), { replace: true });
    }

    return (
        <div>
            <Header
                onBack={() => navigate(-1)}
            />

            <TwoColumnWrapper
                title='Nyt arrangement'
                leftColumn={
                    <EventGeneralForm
                        defaultValues={{
                            [EventGeneralFormFieldEnum.START_DATE]: createEventStore.date,
                            [EventGeneralFormFieldEnum.STATUS]: EventStatusEnum.ACTIVE
                        }}
                        onSubmit={onSubmit}
                        onCancel={() => navigate(-1)}
                        showActionButtonsByDefault={true}
                    />
                }
            />

        </div>
    )

}

export default PageCreateEvent;