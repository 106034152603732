import { TextareaAutosize } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import Error from "../Error";
import Label from "../Label";

interface Props extends FormElementInterface {

}

const Textarea = (props: Props) => {
    const { register } = useFormContext();

    return (
        <div>
            <Label name={props.name} label={props.label} />
            <TextareaAutosize
                className='border p-2 w-full rounded'
                {...register(props.name)}
                style={{ minHeight: '200px' }}
                // minRows={4}
                // rows={4}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
            />
            <Error name={props.name} />
        </div>
    )
}

export default Textarea;