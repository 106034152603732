import Cell from 'components/Table/Cell';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { eventsStore } from 'stores/EventsStore';
import moment from 'moment';
import { EventListInterface } from 'types/EventListInterface';
import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';
import Table from 'components/Table';
import { eventStatusEnumToLabel } from 'helpers/EventStatusHelper';
import _ from 'lodash';
import { SelectMonthEnum } from 'types/SelectMonthEnum';

interface ShadowEventInterface extends EventListInterface {
    isShadowEvent?: boolean
}

const EventsTable = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        eventsStore.getEvents();
    }, [])

    const generateShadowEvents = (events: Array<EventListInterface>) => {
        const resultsArr: Array<ShadowEventInterface> = [];

        if (eventsStore.month === SelectMonthEnum.WHOLE_YEAR) {
            return events;
        }

        for (var i = 1; i <= moment().month(parseInt(eventsStore.month)).daysInMonth(); i++) {
            // eslint-disable-next-line no-loop-func
            events.forEach((ev) => {
                const currentDate = moment()
                    .year(parseInt(eventsStore.year))
                    .month(parseInt(eventsStore.month))
                    .date(i)

                const eventFromDate = moment(ev.from);
                const eventToDate = moment(ev.to);

                if (currentDate.isSame(eventFromDate, 'day')) {
                    resultsArr.push(ev);
                }
                else if (currentDate.isAfter(eventFromDate, 'day') && currentDate.isSameOrBefore(eventToDate, 'day')) {
                    const shadowEv = _.clone(ev) as ShadowEventInterface;
                    shadowEv.isShadowEvent = true;
                    shadowEv.from = eventFromDate.date(i).month(eventsStore.month).toISOString();
                    resultsArr.push(shadowEv);
                }
            })
        }

        return resultsArr;
    }

    let events = generateShadowEvents(eventsStore.filteredEvents);

    return (
        <Table
            data={events}
            defaultSortProperty='from'
            defaultSortDirection='asc'
            onRowClicked={(ev: ShadowEventInterface) => navigate(generatePath(routes.pageEventDetails, { id: ev.id }))}
            rowStyle={(ev: ShadowEventInterface) => {
                return {
                    opacity: ev.isShadowEvent ? 0.5 : 1
                }
            }}
            head={[
                {
                    label: 'Lokale',
                    sortProperty: 'location',
                    rowFormat: (row: ShadowEventInterface) => row.location.name
                },
                {
                    label: 'Dato',
                    sortProperty: 'from',
                    rowFormat: (row: ShadowEventInterface) => new Date(row.from)
                },
                {
                    label: 'Klokkeslet',
                    sortProperty: 'time',
                    rowFormat: (row: ShadowEventInterface) => `${moment(row.from).format('HH:mm')} - ${moment(row.to).format('HH:mm')}`
                },
                {
                    label: 'Titel',
                    sortProperty: 'title'
                },
                {
                    label: 'Kunde',
                    sortProperty: 'customer.name',
                },
                {
                    label: 'Status',
                    sortProperty: 'status',
                    rowFormat: (row: ShadowEventInterface) => eventStatusEnumToLabel(row.status)
                }
            ]}
            renderRow={(ev: ShadowEventInterface) => {
                const momentFrom = moment(ev.from);
                const momentTo = moment(ev.to)

                const cellStyle = {
                    border: 0,
                    fontWeight: !ev.isShadowEvent && !moment(ev.from).isSame(moment(ev.to), 'date') ? 800 : undefined
                }

                return (
                    <>
                        <Cell style={cellStyle} className='hidden'>{ev.location?.name || ''}</Cell>
                        <Cell style={cellStyle}>
                            {
                                ev.from &&
                                <span>{momentFrom.format('DD/MM/YYYY')}</span>
                            }
                            {
                                (!ev.isShadowEvent && ev.to) &&
                                <span>{!momentFrom.isSame(momentTo, 'date') ? ' - ' + momentTo.format('DD/MM/YYYY') : ''}</span>
                            }
                        </Cell>
                        <Cell style={cellStyle}>
                            {
                                (!ev.isShadowEvent && ev.from) &&
                                <span>{momentFrom.format('HH:mm')}</span>
                            }

                            {
                                (!ev.isShadowEvent) && ev.to &&
                                <span> - {momentTo.format('HH:mm')}</span>
                            }

                            {
                                ev.isShadowEvent &&
                                <span>Flere dage</span>
                            }
                        </Cell>
                        <Cell style={cellStyle}>{ev.title}
                        </Cell>
                        <Cell style={cellStyle}>{ev.customer?.name || ''}</Cell>
                        <Cell style={cellStyle}>{eventStatusEnumToLabel(ev.status)}</Cell>
                    </>
                )
            }}
        />
    )
})

export default EventsTable;