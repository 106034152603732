import Button from "components/Forms/Button";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import SettingsWrapper, { HeaderSubMenuItemEnum } from "components/SettingsWrapper";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import WhiteBox from "components/WhiteBox";
import { toast } from "react-toastify";
import { authStore } from "stores/AuthStore";
import { YupInterface } from "types/YupInterface";
import { ChangePasswordFormFieldEnum } from "./ChangePasswordFormFieldEnum";

const PageSettingsChangePassword = () => {

    const onSubmit = async (data: { [x in ChangePasswordFormFieldEnum]: string; }) => {
        if (data.newPassword !== data.newPasswordRepeat) {
            toast('Dit nye password skal være det samme i begge felter', { type: 'error' });
            return;
        }

        try {
            await authStore.changePassword(data.currentPassword, data.newPassword)
            toast('Dit password er blevet skiftet', { type: 'success' });
        }
        catch (error) {
            toast('Ugyldigt password', { type: 'error' });
        }
    }

    const validation = (yup: YupInterface) => {
        return {
            [ChangePasswordFormFieldEnum.CURRENT_PASSWORD]: yup.string().required('Påkrævet'),
            [ChangePasswordFormFieldEnum.NEW_PASSWORD]: yup.string().required('Påkrævet'),
            [ChangePasswordFormFieldEnum.NEW_PASSWORD_REPEAT]: yup.string().required('Påkrævet')
        }
    }

    return (
        <SettingsWrapper
            activeSubMenuItem={HeaderSubMenuItemEnum.CHANGE_PASSWORD}>

            <TwoColumnWrapper
                title='Skift dit password'
                skipNotifyModal={true}
                leftColumn={<div>
                    <WhiteBox className='mb-4 p-6'>
                        <Form
                            resetOnSubmit={true}
                            onSubmit={onSubmit}
                            validation={validation}>
                            <div className='grid gap-8 grid-cols-2 mt-6'>
                                <div>
                                    <Input
                                        type='password'
                                        name={ChangePasswordFormFieldEnum.CURRENT_PASSWORD}
                                        label='Nuværende password'
                                    />

                                </div>
                                <div>
                                    <Input
                                        type='password'
                                        name={ChangePasswordFormFieldEnum.NEW_PASSWORD}
                                        label='Nyt password'
                                    />
                                    <Input
                                        type='password'
                                        name={ChangePasswordFormFieldEnum.NEW_PASSWORD_REPEAT}
                                        label='Nyt password igen'
                                    />
                                </div>
                            </div>
                            <div className='text-right mt-8'>
                                <Button
                                    type='submit'
                                    label='Skift password'
                                    appearance='primary'
                                />
                            </div>
                        </Form>
                    </WhiteBox>
                </div>}
            />
        </SettingsWrapper>
    )
}

export default PageSettingsChangePassword;