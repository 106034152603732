import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    label: string,
    icon: IconDefinition,
    isActive?: boolean,
    onClick?: () => void
}

const HeaderMenuItem = (props: Props) => {
    return (
        <li
            onClick={props.onClick}
            className={`
                inline-block 
                mx-4 
                text-center 
                cursor-pointer
                ${!props.isActive ? 'opacity-80' : ''}
            `}>
            <div 
                className={`
                    inline
                    bg-[#7D3131]
                    px-2
                    py-1
                    rounded-full
                `}>
                <FontAwesomeIcon icon={props.icon} className='text-white' />
            </div>
            <p className={`
                text-xs
                text-white
                mt-2
                ${props.isActive ? 'font-semibold' : ''}
            `}>{props.label}</p>
        </li>
    )
}

export default HeaderMenuItem;