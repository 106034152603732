import { Controller, useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import DatePicker, { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import Label from "components/Forms/Label";
import Error from "components/Forms/Error";
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from "date-fns";
import moment from 'moment';
import React, { useRef, useState } from "react";

registerLocale('da-DK', da);

const today = new Date();

interface Props extends FormElementInterface {
    format?: 'date' | 'datetime' | 'time',
    minHours?: number,
    maxHours?: number,
}

const SelectDateTime = (props: Props) => {
    const ctx = useFormContext();
    const [isOpen, setIsOpen] = useState(false);

    let options = { showTimeSelect: false, showTimeSelectOnly: false, dateFormat: 'E dd/M/yy (w)' }
    if (props.format === 'datetime') {
        options = { showTimeSelect: true, showTimeSelectOnly: false, dateFormat: 'E dd/M/yy HH:mm (w)' }
    }
    if (props.format === 'time') {
        options = { showTimeSelect: true, showTimeSelectOnly: true, dateFormat: 'E dd/M/yy (w)' }
    }

    return (
        <Controller
            name={props.name}
            control={ctx.control}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => {
                const onValueChanged = (date: Date, oldValue?: Date) => {
                    const momentDate = moment(date);

                    if (props.format === 'time') {
                        // If we are only using the time selector, set the date to
                        // 1st of january. That way the backend will correctly set the timezone
                        // based on UTC.
                        const timeDate = moment().startOf('year').set({
                            hour: momentDate.get('hour'),
                            minute: momentDate.get('minute'),
                        }).toDate();

                        onChange(timeDate);
                    }
                    else {
                        onChange(date);

                        if (oldValue && moment(oldValue).format('HH:mm') !== momentDate.format('HH:mm')) {
                            setIsOpen(false);
                        }
                    }
                }

                return (
                    <div className={props.className ?? 'w-full'}>
                        <Label name={name} label={props.label} />

                        <DatePicker
                            open={isOpen}
                            ref={ref}
                            onBlur={onBlur}
                            onChange={(val) => onValueChanged(val as Date, value)}
                            selected={value}
                            // onChangeRaw={onValueChangedRaw}
                            placeholderText={props.placeholder}
                            showWeekNumbers={true}
                            locale='da-DK'
                            // timeFormat='p'
                            onInputClick={() => setIsOpen(true)}
                            onClickOutside={() => setIsOpen(false)}
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            timeIntervals={15}
                            startDate={props.format === 'time' ? moment().startOf('year').toDate() : undefined}
                            minTime={props.minHours ? setHours(setMinutes(today, 0), props.minHours) : undefined}
                            maxTime={props.maxHours ? setHours(setMinutes(today, 0), props.maxHours) : undefined}
                            className="border p-2 h-10 w-full rounded"
                            onFocus={(e: any) => {
                                const { target } = e;
                            
                                if (target) {
                                  target.readOnly = true;  // -------> this for all others
                                  target.blur(); //  ------> this for ios iphone, TV Browsers, Ipad, Safari
                                }
                              }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setIsOpen(false);
                                }
                            }}
                            {...options}
                        />

                        <Error name={props.name} />
                    </div>
                )
            }}
        />
    )
}

export default SelectDateTime;