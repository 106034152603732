import ExpandButton from "components/ExpandButton";
import ResourceGeneralForm from "components/Forms/ResourceGeneralForm";
import { ResourceGeneralFormFieldEnum } from "components/Forms/ResourceGeneralForm/ResourceGeneralFormFieldEnum";
import Header from "components/Header";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resourceDetailsstore } from "stores/ResourceDetailsStore";

const PageResourceDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            resourceDetailsstore.getResource(id);
        }
        return () => {
            resourceDetailsstore.resetStore();
        }
    }, [id])

    const onSubmit = async (data: { [x in ResourceGeneralFormFieldEnum]: string; }) => {
        const resource = _.clone(resourceDetailsstore.resource);
        if (!resource) return;

        resource.name = data.name;
        resource.description = data.description;
        resource.color = data.color;

        await resourceDetailsstore.updateResource(resource);
        toast('Ændringer gemt', { type: 'success' });
    }

    const onDelete = async () => {
        if (!window.confirm('Er du sikker på at du vil slette dette lokale?')) return;

        await resourceDetailsstore.deleteResource(id!);
        navigate(routes.pageSettingsResources);
    }

    const resource = resourceDetailsstore.resource;
    if (!resource) return null;

    return (
        <div>
            <Header onBack={() => navigate(-1)} />
            <TwoColumnWrapper
                skipNotifyModal={true}
                title='Lokale'
                topRight={
                    <ExpandButton
                        label='Handlinger'
                        items={[
                            {
                                label: 'Slet lokale',
                                onClick: () => onDelete()
                            }
                        ]}
                    />
                }
                leftColumn={
                    <ResourceGeneralForm
                        defaultValues={{
                            [ResourceGeneralFormFieldEnum.NAME]: resource.name,
                            [ResourceGeneralFormFieldEnum.DESCRIPTION]: resource.description,
                            [ResourceGeneralFormFieldEnum.COLOR]: resource.color || '',
                        }}
                        onSubmit={onSubmit}
                    />
                }
            />
        </div>
    )
})

export default PageResourceDetails;