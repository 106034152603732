import { Table as MaterialUITable, TableHead, TableBody, TableContainer, TableRow, TableSortLabel } from '@material-ui/core';
import { useState } from 'react';
import Cell from './Cell';
import SortLabel from './SortLabel';
import _ from 'lodash';

interface HeadInterface {
    label: string,
    sortProperty: string,
    rowFormat?: (row: any) => void
}

interface Props {
    data: Array<any>,
    head: Array<HeadInterface>,
    defaultSortProperty: string,
    defaultSortDirection: 'asc' | 'desc',
    renderRow: (item: any) => React.ReactNode,
    onRowClicked?: (item: any) => void,
    rowStyle?: (item: any) => React.CSSProperties
}

const Table = (props: Props) => {
    const [orderFunc, setOrderFunc] = useState<Function | null>(null)
    const [orderBy, setOrderBy] = useState(props.defaultSortProperty);
    const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>(props.defaultSortDirection);

    const onSort = (property: string, orderFunction?: (row: any) => void) => {
        if (orderFunction) {
            setOrderFunc(orderFunction);
        }
        else {
            setOrderFunc(null);
        }

        if (orderBy === property) {
            orderDirection === 'asc' ? setOrderDirection('desc') : setOrderDirection('asc')
        }
        else {
            setOrderBy(property);
            setOrderDirection('asc');
        }
    }

    const data = _.orderBy(props.data, orderFunc ? (val) => orderFunc(val) : orderBy, orderDirection);

    return (
        <TableContainer>
            <MaterialUITable>
                <TableHead>
                    <TableRow>
                        {
                            props.head.map((h, i) => {
                                return (
                                    <Cell key={i}>
                                        <SortLabel
                                            label={h.label}
                                            sortProperty={h.sortProperty}
                                            activeProperty={orderBy}
                                            direction={orderDirection}
                                            onSort={onSort}
                                            rowFormat={h.rowFormat}
                                        />
                                    </Cell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((item, i) => {

                            return (
                                <TableRow style={props.rowStyle ? props.rowStyle(item) : undefined} key={i} onClick={() => props.onRowClicked ? props.onRowClicked(item) : undefined} >
                                    {props.renderRow(item)}
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </MaterialUITable>
        </TableContainer>
    )
}

export default Table;