interface Props {
    label: string,
    onClick?: () => void,
    isActive?: boolean
    style?: React.CSSProperties
}

const HeaderSubMenuItem = (props: Props) => (
    <li
        style={props.style}
        className={`
            inline-block 
            mx-4
            ${!props.isActive ? 'opacity-80' : ''}
        `}>
        <button
            className={props.isActive ? 'font-semibold underline' : ''}
            onClick={props.onClick}
            type='button'>{props.label}</button>
    </li>
)

export default HeaderSubMenuItem;