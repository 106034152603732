import { ApiTokenInterface } from 'types/ApiTokenInterface';
import { BaseStore } from './BaseStore';
import { action, makeObservable, observable } from 'mobx';
import { CreateApiTokenInterface } from 'types/CreateApiTokenInterface';

class ApiTokensStore extends BaseStore {

    tokens: Array<ApiTokenInterface> = [];

    constructor() {
        super();

        makeObservable(this, {
            tokens: observable,

            resetStore: action,
            getTokens: action,
            deleteToken: action,
            createToken: action
        })
    }

    resetStore = () => {
        this.tokens = [];
    }

    getTokens = () => {
        return this.get(`tokens`, 'tokens');
    }

    deleteToken = (id: string) => {
        return this.delete(`tokens/${id}`, id, this.tokens);
    }

    createToken = (token: CreateApiTokenInterface) => {
        return this.post(`tokens`, token, this.tokens, true)
    }
}

export const apiTokensStore = new ApiTokensStore();