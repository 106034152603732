import SelectResource from "components/SelectResource";
import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { UserRoleEnum } from "types/UserRoleEnum";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import FormFieldWatcher from "../FormFieldWatcher";
import Input from "../Input";
import SelectContactPerson from "../SelectContactPerson";
import SelectCustomer from "../SelectCustomer";
import SelectDateTime from "../SelectDateTime";
import SelectEventStatus from "../SelectEventStatus";
import Textarea from "../Textarea";
import { EventGeneralFormFieldEnum } from "./EventGeneralFormFieldEnum";
import SelectEndDate from "./SelectEndDate";

interface Props {
    defaultValues?: { [x in EventGeneralFormFieldEnum]?: any },
    onSubmit: (data: { [x in EventGeneralFormFieldEnum]: string; }) => void,
    showActionButtonsByDefault?: boolean,
    onCancel?: () => void
}

const EventGeneralForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [EventGeneralFormFieldEnum.TITLE]: yup.string().required('Påkrævet'),
            [EventGeneralFormFieldEnum.START_DATE]: yup.string().required('Påkrævet'),
            [EventGeneralFormFieldEnum.END_DATE]: yup.string().required('Påkrævet'),
            [EventGeneralFormFieldEnum.CUSTOMER]: yup.object().nullable().required('Påkrævet'),
            [EventGeneralFormFieldEnum.RESOURCE]: yup.string().required('Påkrævet'),
            [EventGeneralFormFieldEnum.STATUS]: yup.string().required('Påkrævet'),
        }
    }

    const onContactPersonChanged = (newContactId: string) => {
        const originalContactId = props.defaultValues?.contact?.id;
        if (newContactId !== originalContactId) {
            setIsFormDirty(true);
        }
    }

    const onCustomerChanged = (newCustomerId: string) => {
        const originalCustomerId = props.defaultValues?.customer?.id;
        if (newCustomerId !== originalCustomerId) {
            setIsFormDirty(true);
        }
    }

    return (
        <WhiteBox className='print:hidden mb-4 p-6'>
            <div className='flex items-center place-content-between'>
                <h1 className='text-xl'>
                    Generelt
                </h1>
            </div>
            <fieldset disabled={authStore.user?.role !== UserRoleEnum.ADMIN}>
                <Form
                    onSubmit={props.onSubmit}
                    formOptions={{ defaultValues: props.defaultValues }}
                    validation={validation}
                    onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
                >
                    <div className='grid gap-8 grid-cols-2 mt-6'>
                        <div>
                            <div className='pb-3'>
                                <SelectDateTime
                                    name={EventGeneralFormFieldEnum.START_DATE}
                                    label='Start dato og tid'
                                    className='w-full'
                                    format="datetime"
                                />
                            </div>

                            <Input name={EventGeneralFormFieldEnum.TITLE} label='Titel' />

                            <div className='pb-3'>
                                <Textarea
                                    label='Beskrivelse i kalender'
                                    name={EventGeneralFormFieldEnum.DESCRIPTION}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='pb-3'>
                                <SelectEndDate syncWithField={EventGeneralFormFieldEnum.START_DATE} />
                            </div>

                            <div className='pb-3'>
                                <SelectCustomer
                                    name={EventGeneralFormFieldEnum.CUSTOMER}
                                    label='Kunde'
                                    placeholder='Begynd at skrive...'
                                />
                                <FormFieldWatcher field={EventGeneralFormFieldEnum.CUSTOMER} onChange={onCustomerChanged} />
                            </div>

                            <div className='pb-3'>
                                <SelectContactPerson
                                    selectedCustomerName={EventGeneralFormFieldEnum.CUSTOMER}
                                    name={EventGeneralFormFieldEnum.CONTACT_PERSON}
                                />
                                <FormFieldWatcher field={EventGeneralFormFieldEnum.CONTACT_PERSON} onChange={onContactPersonChanged} />
                            </div>

                            <div className='pb-3'>
                                <SelectResource
                                    placeholder='Vælg lokale'
                                    name={EventGeneralFormFieldEnum.RESOURCE}
                                />
                            </div>

                            <div className='pb-3'>
                                <SelectEventStatus
                                    name={EventGeneralFormFieldEnum.STATUS}
                                    placeholder='Vælg status'
                                />
                            </div>

                        </div>
                    </div>
                    {
                        (props.showActionButtonsByDefault || isFormDirty) &&
                        <div className='text-right'>
                            {
                                props.onCancel &&
                                <Button
                                    onClick={() => props.onCancel!()}
                                    type='button'
                                    appearance='secondary'
                                    label='Fortryd'
                                    className='mr-4'
                                />
                            }

                            <Button
                                type='submit'
                                label='Gem'
                                appearance='primary'
                            />
                        </div>
                    }

                </Form>
            </fieldset>
        </WhiteBox>
    )

}

export default EventGeneralForm;