import ExpandButton from "components/ExpandButton";
import ContactGeneralForm from "components/Forms/ContactGeneralForm";
import { ContactGeneralFormFieldEnum } from "components/Forms/ContactGeneralForm/ContactGeneralFormFieldEnum";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import WhiteBox from "components/WhiteBox";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { customerDetailsStore } from "stores/CustomerDetailsStore";
import { ContactListInterface } from "types/ContactListInterface";
import _ from 'lodash';
import { toast } from "react-toastify";
import CreateContact from "./CreateContact";

interface Props {
    customerId: string
}

const CustomerContactList = observer((props: Props) => {

    const customerId = props.customerId;
    useEffect(() => {
        customerDetailsStore.getContacts(customerId);
    }, [customerId])

    const onSubmit = async (contact: ContactListInterface, data: { [x in ContactGeneralFormFieldEnum]: string }) => {
        const newContact = _.clone(contact);

        newContact.name = data.name;
        newContact.title = data.title;
        newContact.mobile = data.mobile;
        newContact.phone = data.phone;
        newContact.email = data.email;

        await customerDetailsStore.updateContact(customerId, newContact);
        toast('Ændringer gemt', { type: 'success' });
    }

    const onDelete = async (contactId: string) => {
        if (!window.confirm('Er du sikker på at du vil slette denne kontaktperson?')) return;

        await customerDetailsStore.deleteContact(customerId, contactId);
    }

    const contacts = customerDetailsStore.contacts;

    return (
        <>
            {
                contacts.map((contact, index) => {
                    return (
                        <ContactGeneralForm
                            key={index}
                            defaultValues={{
                                [ContactGeneralFormFieldEnum.NAME]: contact.name,
                                [ContactGeneralFormFieldEnum.TITLE]: contact.title,
                                [ContactGeneralFormFieldEnum.MOBILE]: contact.mobile,
                                [ContactGeneralFormFieldEnum.PHONE]: contact.phone,
                                [ContactGeneralFormFieldEnum.EMAIL]: contact.email
                            }}
                            onSubmit={(data) => onSubmit(contact, data)}
                            onDelete={() => onDelete(contact.id)}
                        />
                    )
                })
            }
            <CreateContact customerId={customerId} />
        </>
    )
})

export default CustomerContactList;