import { EventListInterface } from "types/EventListInterface";
import moment from 'moment';

export const exportToCsv = (filename: string, rows: any[]) => {
    if (!rows || !rows.length) {
        return;
    }
    const separator = ';';
    const keys = Object.keys(rows[0]);
    // \ufeff is a BOM character to tell Excel, that we are using UTF-8 for encoding.
    // More info: https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
    // This fixes rendering of scandinavian characters in Excel.
    const csvContent =
        "#" +
        keys.join(separator) +
        '\n' +
        rows.map(row => {
            return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                cell = cell instanceof Date
                    ? cell.toLocaleString()
                    : cell.toString().replace(/"/g, '""');
                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator);
        }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=ISO-8859-15;' });
    const msSaveBlob = (window.navigator as any).msSaveBlob;
    if (msSaveBlob) { // IE 10+
        msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const exportToLessorFormattedCsv = (filename: string, event: EventListInterface, lessorId?: number) => {
    exportToCsv(filename, [
        {
            title: event.title,
            eventId: lessorId || '',
            from: moment(event.from).format('YYYY-MM-DD'),
            to: moment(event.to).format('YYYY-MM-DD'),
            costcenterCode: '',
            description: '',
            color: event.location?.color || '',
            information: 'SKRIV IKKE HER! BENYT TEATERADMIN.DK',
            isActive: 1,
            isGlobal: 1,
            location: event.location?.name || '',
            client: event.customer?.name || '',
            detailedTimesStart: `[${moment(event.from).format('YYYY-MM-DD HH:mm:00')}]`,
            detailedTimesEnd: `[${moment(event.to).format('YYYY-MM-DD HH:mm:00')}]`,
            detailedTimesDetailType: '',
            detailedTimesInformation: '',
            groupDepartment: '',
            group: '',
            extraInfoFieldValue1: '',
            extraInfoFieldValue2: '',
            extraInfoFieldValue3: '',
            extraInfoFieldValue4: '',
            extraInfoFieldValue5: '',
            extraInfoFieldValue6: '',
            extraInfoFieldValue7: '',
            extraInfoFieldValue8: '',
            extraInfoFieldValue9: '',
            extraInfoFieldValue10: '',
            extraInfoFieldIsVisible1: 1,
            extraInfoFieldIsVisible2: 1,
            extraInfoFieldIsVisible3: 1,
            extraInfoFieldIsVisible4: 1,
            extraInfoFieldIsVisible5: 1,
            extraInfoFieldIsVisible6: 1,
            extraInfoFieldIsVisible7: 1,
            extraInfoFieldIsVisible8: 1,
            extraInfoFieldIsVisible9: 1,
            extraInfoFieldIsVisible10: 1,
            removeFiles: 0,
            datestamp: moment().format('YYYY-MM-DD')
        }
    ])
}