import { routes } from 'components/Routes';
import Table from 'components/Table';
import Cell from 'components/Table/Cell';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { resourceStore } from 'stores/ResourceStore';
import { ResourceListInterface } from 'types/ResourceListInterface';

const ResourcesTable = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        resourceStore.getResources();

        return () => {
            resourceStore.resetStore();
        }
    }, [])

    const resources = resourceStore.resources;

    return (
        <Table
            data={resources}
            defaultSortProperty='name'
            defaultSortDirection='asc'
            onRowClicked={(r: ResourceListInterface) => navigate(generatePath(routes.pageResourceDetails, { id: r.id }))}
            head={[
                {
                    label: 'Navn',
                    sortProperty: 'name'
                },
                {
                    label: 'Beskrivelse',
                    sortProperty: 'description'
                },
            ]}
            renderRow={(resource: ResourceListInterface) => {
                return (
                    <>
                        <Cell>
                            <div>
                                <div
                                    style={{
                                        background: resource.color || '#000000',
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '8px',
                                        borderRadius: '50%',
                                        display: 'inline-block'
                                    }}>
                                    &nbsp;
                                </div>
                                {resource.name}
                            </div>

                        </Cell>
                        <Cell>{resource.description}</Cell>
                    </>
                )
            }}
        />
    )
})

export default ResourcesTable;