import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from "react-router-dom"
import ToastContainer from 'components/ToastContainer';
import PageLogin from "components/Pages/PageLogin";
import LoggedInContainer from "components/LoggedInContainer";
import PageFront from "components/Pages/PageFront";
import PageCalendar from "components/Pages/PageCalendar";
import PageResources from "components/PageResources";
import PageUserNotifications from "components/Pages/PageUserNotifications";
import PageEventDetails from "components/Pages/PageEventDetails";
import PageSettingsCustomers from "components/Pages/PageSettingsCustomers";
import PageSettingsResources from "components/Pages/PageSettingsResources";
import PageSettingsUsers from "components/Pages/PageSettingsUsers";
import PageCustomerDetails from "components/Pages/PageCustomerDetails";
import PageForgotPassword from "components/Pages/PageForgotPassword";
import PageResetPassword from "components/PageResetPassword";
import PageCreateEvent from "components/Pages/PageCreateEvent";
import PageEventDetailsNotifications from "components/Pages/PageEventDetailsNotifications";
import PageCreateCustomer from "components/Pages/PageCreateCustomer";
import PageResourceDetails from "components/Pages/PageResourceDetails";
import PageCreateResource from "components/Pages/PageCreateResource";
import PageUserDetails from "components/Pages/PageUserDetails";
import PageCreateUser from "components/Pages/PageCreateUser";
import { createBrowserHistory } from "history";
import PageDuplicateEvent from "components/Pages/PageDuplicateEvent";
import LoadingIndicator from "components/LoadingIndicator";
import PageSettingsChangePassword from "components/Pages/PageSettingsChangePassword";
import PageSettingsApiTokens from 'components/Pages/PageSettingsApiTokens';
import PageCreateApiToken from 'components/Pages/PageCreateApiToken';
import PageEventDetailsLog from 'components/Pages/PageEventDetailsLog';

export const history = createBrowserHistory();

export const routes = {
    pageFront: '/',
    pageLogin: '/login',
    pageForgotPassword: '/forgot-password',
    pageResetPassword: '/reset-password/:token',
    pageCalendar: '/calendar',
    pageResources: '/resources',
    pageUserNotifications: '/notifications',
    pageCreateEvent: '/create-event',
    pageEventDetails: '/events/:id',
    pageDuplicateEvent: '/events/:id/duplicate',
    pageEventDetailsNotifications: '/events/:id/notifications',
    pageEventDetailsLog: '/events/:id/log',
    pageSettingsUsers: '/settings/users',
    pageCreateUser: '/create-user',
    pageSettingsApiTokens: '/settings/api-tokens',
    pageCreateApiToken: '/create-api-token',
    pageUserDetails: '/settings/users/:id',
    pageSettingsResources: '/settings/resources',
    pageCreateResource: '/create-resource',
    pageResourceDetails: '/settings/resources/:id',
    pageSettingsCustomers: '/settings/customers',
    pageCreateCustomer: '/create-customer',
    pageCustomerDetails: '/settings/customers/:id',
    pageSettingsChangePassword: '/settings/change-password'
}

const MyRoutes = () => {
    return (
        <div>
            <ToastContainer position='top-center' autoClose={2000} />
            <HistoryRouter history={history}>
                <Routes>
                    <Route path={routes.pageLogin} element={<PageLogin />} />
                    <Route path={routes.pageForgotPassword} element={<PageForgotPassword />} />
                    <Route path={routes.pageResetPassword} element={<PageResetPassword />} />
                    <Route element={<LoggedInContainer />}>
                        <Route path={routes.pageFront} element={<PageFront />} />
                        <Route path={routes.pageCalendar} element={<PageCalendar />} />
                        <Route path={routes.pageResources} element={<PageResources />} />
                        <Route path={routes.pageUserNotifications} element={<PageUserNotifications />} />
                        <Route path={routes.pageCreateEvent} element={<PageCreateEvent />} />
                        <Route path={routes.pageEventDetails} element={<PageEventDetails />} />
                        <Route path={routes.pageDuplicateEvent} element={<PageDuplicateEvent />} />
                        <Route path={routes.pageEventDetailsNotifications} element={<PageEventDetailsNotifications />} />
                        <Route path={routes.pageEventDetailsLog} element={<PageEventDetailsLog />} />
                        
                        <Route path={routes.pageSettingsCustomers} element={<PageSettingsCustomers />} />
                        <Route path={routes.pageCreateCustomer} element={<PageCreateCustomer />} />
                        <Route path={routes.pageCustomerDetails} element={<PageCustomerDetails />} />

                        <Route path={routes.pageCreateResource} element={<PageCreateResource />} />
                        <Route path={routes.pageSettingsResources} element={<PageSettingsResources />} />
                        <Route path={routes.pageResourceDetails} element={<PageResourceDetails />} />
                        
                        <Route path={routes.pageSettingsUsers} element={<PageSettingsUsers />} />
                        <Route path={routes.pageCreateUser} element={<PageCreateUser />} />
                        <Route path={routes.pageUserDetails} element={<PageUserDetails />} />

                        <Route path={routes.pageSettingsApiTokens} element={<PageSettingsApiTokens />} />
                        <Route path={routes.pageCreateApiToken} element={<PageCreateApiToken />} />

                        <Route path={routes.pageSettingsChangePassword} element={<PageSettingsChangePassword />} />

                    </Route>
                </Routes>
            </HistoryRouter>
            <LoadingIndicator />
        </div>

    )
}

export default MyRoutes;