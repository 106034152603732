import Button from "components/Forms/Button";
import { useFormContext } from "react-hook-form";

interface Props {
    afterReset?: () => void
}

const ResetFormButton = (props: Props) => {
    const { reset } = useFormContext();

    const onClick = () => {
        reset();

        if (props.afterReset) {
            props.afterReset();
        }
    }
 
    return (
        <Button
            onClick={onClick}
            type='button'
            label='Fortryd'
            appearance='secondary'
            className='mr-4'
        />
    )
}

export default ResetFormButton;