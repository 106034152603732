import ExpandButton from "components/ExpandButton";
import UserGeneralForm from "components/Forms/UserGeneralForm";
import { UserGeneralFormFieldEnum } from "components/Forms/UserGeneralForm/UserGeneralFormFieldEnum";
import Header from "components/Header";
import { routes } from "components/Routes";
import TwoColumnWrapper from "components/TwoColumnWrapper";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authStore } from 'stores/AuthStore';
import { userDetailsStore } from "stores/UserDetailsStore";
import { UserRoleEnum } from "types/UserRoleEnum";

const PageUserDetails = observer(() => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            userDetailsStore.getUser(id);
        }
        return () => {
            userDetailsStore.resetStore();
        }
    }, [id])

    const onSubmit = async (data: { [x in UserGeneralFormFieldEnum]: string; }) => {
        const user = _.clone(userDetailsStore.user);
        if (!user) return;

        user.name = data.name;
        user.email = data.email;
        user.phone = data.phone;
        user.title = data.title;
        user.role = data.role as any;

        await userDetailsStore.updateUser(user);
        toast('Ændringer gemt', { type: 'success' });
    }

    const onDelete = async () => {
        if (!window.confirm('Er du sikker på at du vil slette denne bruger?')) return;

        await userDetailsStore.deleteUser(id!);
        navigate(routes.pageSettingsUsers);
    }

    const onResendPassword = async (userId: string) => {
        if (!window.confirm('Er du sikker på at du vil generere et nyt password og sende til brugeren?')) return;

        await authStore.resendPasswords([userId]);
        toast('Der er blevet sendt en mail til bruger med nyt password', { type: 'success' });
    }

    const user = userDetailsStore.user;
    if (!user) return null;

    return (
        <div>
            <Header onBack={() => navigate(-1)} />
            <TwoColumnWrapper
                skipNotifyModal={true}
                title='Bruger'
                topRight={
                    <ExpandButton
                        label='Handlinger'
                        items={[
                            {
                                label: 'Send password',
                                onClick: () => onResendPassword(id!)
                            },
                            {
                                label: 'Slet bruger',
                                onClick: () => onDelete()
                            }
                        ]}
                    />
                }
                leftColumn={
                    <UserGeneralForm
                        defaultValues={{
                            [UserGeneralFormFieldEnum.NAME]: user.name,
                            [UserGeneralFormFieldEnum.EMAIL]: user.email,
                            [UserGeneralFormFieldEnum.PHONE]: user.phone,
                            [UserGeneralFormFieldEnum.TITLE]: user.title,
                            [UserGeneralFormFieldEnum.ROLE]: user.role
                        }}
                        onSubmit={onSubmit}
                    />
                }
            />
        </div>
    )
})

export default PageUserDetails;