import CustomersTable from "components/CustomersTable";
import { routes } from "components/Routes";
import SettingsWrapper, { HeaderSubMenuItemEnum } from "components/SettingsWrapper";
import { useNavigate } from "react-router-dom";

const PageSettingsCustomers = () => {
    const navigate = useNavigate();

    return (
        <SettingsWrapper 
            title='Kunder' 
            actionButton={{ label: 'Opret kunde', onClick: () => navigate(routes.pageCreateCustomer) }}
            activeSubMenuItem={HeaderSubMenuItemEnum.CUSTOMERS}>
            <CustomersTable />
        </SettingsWrapper>
    )
}

export default PageSettingsCustomers;