import { action, makeObservable, observable } from "mobx";
import { CreateResourceInterface } from "types/CreateResourceInterface";
import { ResourceListInterface } from "types/ResourceListInterface";
import { BaseStore } from "./BaseStore";

class CreateResourceStore extends BaseStore {

    resource: ResourceListInterface | null = null;

    constructor() {
        super();

        makeObservable(this, {
            resource: observable,
            resetStore: action,
            createResource: action
        })
    }

    resetStore = () => {
        this.resource = null;
    }

    createResource = (resource: CreateResourceInterface): Promise<ResourceListInterface> => {
        return this.post(`resources`, resource, 'resource');
    }

}

export const createResourceStore = new CreateResourceStore();