import { action, makeObservable, observable } from "mobx";
import { NotificationInterface } from "types/NotificationInterface";
import { PagingResultInterface } from "types/PagingResultInterface";
import { BaseStore } from "./BaseStore";

class NotificationsStore extends BaseStore {

    notifications: PagingResultInterface<NotificationInterface> | null = null;

    constructor() {
        super();

        makeObservable(this, {
            notifications: observable,
            resetStore: action,
            getNotifications: action
        })
    }

    resetStore = () => {
        this.notifications = null;
    }

    getNotifications = () => {
        return this.getPaged(`notifications`, this.notifications, 'notifications');
    }

}

export const notificationsStore = new NotificationsStore();