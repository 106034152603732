import { fileStore } from 'stores/FileStore';
import FileInterface from 'types/FileInterface';

export const MAX_FILE_SIZE = 94371840; // 90 mb

export function humanFileSize(size: number) {
    var i = Math.floor(Math.log(size) / Math.log(1024)) as number;
    const x = (size / Math.pow(1024, i)).toFixed(2) as any;
    return x * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export async function uploadFile(file: File, maxFileSize: number = MAX_FILE_SIZE) {
    return new Promise(async (resolve, reject) => {
        if (file.size > maxFileSize) {
            reject(`Filen er for stor. Max filstørrelse er ${humanFileSize(maxFileSize)}.`);
        }
        else {
            const uploadInfo = await fileStore.createFile({
                name: file.name,
                contentSize: file.size,
                mimeType: file.type || 'application/octet-stream'
            });

            await fileStore.uploadFileToAzureBlob(uploadInfo.presignedUrl, file);

            const verifiedFile = await fileStore.verifyFile(uploadInfo.file.id);
            resolve(verifiedFile);
        }
    })

}

export const formatFilesAsStringList = (files: FileInterface[]) => {
    let filesStringList = '';
    files.map((file) => {
        return filesStringList += file.name + '\n';
    });
    return filesStringList;
}