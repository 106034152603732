import request from "helpers/Request";
import { StatusCodes } from "http-status-codes";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { NotifyUserInterface } from "types/NotifyUserInterface";

class NotifyRecipientsModalStore {

    eventId: string | null = null;
    sectionId?: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    closeModal = () => {
        this.eventId = null;
        this.sectionId = null;
        toast('Ændringer gemt', { type: 'success' });
    }

    showModal = (eventId: string, sectionId: string | null = null) => {
        this.eventId = eventId;
        this.sectionId = sectionId;
    }

    get isModalVisible() {
        return this.eventId !== null;
    }

    notify = (eventId: string, sectionId: string | null, message: string, users: Array<NotifyUserInterface>) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`events/${eventId}/notify`, {
                    message: message,
                    sections: sectionId ? [{ id: sectionId }] : undefined,
                    users: users
                });

                if (res.status === StatusCodes.CREATED) {
                    resolve(true);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

}

export const notifyRecipientsModalStore = new NotifyRecipientsModalStore();