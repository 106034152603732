import React from "react";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
    name: string,
    trueComponent: React.ReactNode,
    falseComponent: React.ReactNode
}

const ToggleTrueFalse = (props: Props) => {
    const { control, setValue } = useFormContext();

    return (
        <div>
            <Controller
                name={props.name}
                control={control}
                defaultValue={false}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => (
                    <div className='cursor-pointer'>
                        {
                            value === true &&
                            <span className='cursor-pointer' onClick={() => setValue(props.name, false)}>
                                {props.trueComponent}
                            </span>
                        }

                        {
                            value !== true &&
                            <span className='cursor-pointer' onClick={() => setValue(props.name, true)}>
                                {props.falseComponent}
                            </span>
                        }
                    </div>
                )} />
        </div>
    )

}

export default ToggleTrueFalse;