import WhiteBox from "components/WhiteBox";
import { useState } from "react";
import { YupInterface } from "types/YupInterface";
import Button from "../Button";
import Form from "../Form";
import Input from "../Input";
import SelectColor from "../SelectColor";
import Textarea from "../Textarea";
import { ResourceGeneralFormFieldEnum } from "./ResourceGeneralFormFieldEnum";

interface Props {
    defaultValues?: { [x in ResourceGeneralFormFieldEnum]?: any },
    onSubmit: (data: { [x in ResourceGeneralFormFieldEnum]: string; }) => void,
    showActionButtonsByDefault?: boolean,
    onCancel?: () => void
}

const ResourceGeneralForm = (props: Props) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    const validation = (yup: YupInterface) => {
        return {
            [ResourceGeneralFormFieldEnum.NAME]: yup.string().required('Påkrævet')
        }
    }

    return (
        <WhiteBox className='mb-4 p-6'>
            <div className='flex items-center place-content-between'>
                <h1 className='text-xl'>
                    Generelt
                </h1>
            </div>
            <Form
                onSubmit={props.onSubmit}
                formOptions={{ defaultValues: props.defaultValues }}
                validation={validation}
                onIsDirty={(isDirty) => setIsFormDirty(isDirty)}
            >
                <div className='grid gap-8 grid-cols-2 mt-6'>
                    <div>
                        <Input name={ResourceGeneralFormFieldEnum.NAME} label='Navn' />
                        <div className='pb-3'>
                            <Textarea
                                label='Kort beskrivelse'
                                name={ResourceGeneralFormFieldEnum.DESCRIPTION}
                            />
                        </div>


                    </div>
                    <div>
                        <SelectColor
                            label='Farve'
                            name='color'
                        />
                    </div>
                </div>
                {
                    (props.showActionButtonsByDefault || isFormDirty) &&
                    <div className='text-right'>
                        {
                            props.onCancel &&
                            <Button
                                onClick={() => props.onCancel!()}
                                type='button'
                                appearance='secondary'
                                label='Fortryd'
                                className='mr-4'
                            />
                        }

                        <Button
                            type='submit'
                            label='Gem'
                            appearance='primary'
                        />
                    </div>
                }

            </Form>
        </WhiteBox>
    )

}

export default ResourceGeneralForm;