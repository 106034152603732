import SelectDateTime from "components/Forms/SelectDateTime";
import moment from "moment";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
    syncWithField: string
}

const SelectEndDate = (props: Props) => {
    const { watch, setValue, getValues, trigger } = useFormContext();

    const [syncWithField] = watch([props.syncWithField]);

    useEffect(() => {
        const endDate = getValues('endDate');
        if (!syncWithField) return;

        const oneHourLaterThanStartDate = moment(syncWithField).add(1, 'hour').toDate();

        if (!endDate) {
            setValue('endDate', oneHourLaterThanStartDate);
            trigger('endDate');
        }
        else {
            const momentStart = moment(syncWithField);
            const momentEnd = moment(endDate)
            if (momentEnd.isBefore(momentStart)) {
                setValue('endDate', oneHourLaterThanStartDate);
                trigger('endDate');
            }
        }

    }, [syncWithField, getValues, setValue, trigger]);


    return (
        <SelectDateTime
            name='endDate'
            label='Slut dato og tid'
            className='w-full'
            format="datetime"
        />
    )
}

export default SelectEndDate;