import { Controller, useFormContext } from "react-hook-form";
import FormElementInterface from "types/FormElementInterface";
import { HexColorPicker } from "react-colorful";
import Label from "../Label";

interface Props extends FormElementInterface {

}

const SelectColor = (props: Props) => {
    const ctx = useFormContext();

    return (
        <Controller
            name={props.name}
            control={ctx.control}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => {
                const onValueChanged = (value: string) => {
                    onChange(value);
                }

                return (
                    <div className={props.className ?? 'w-full'}>
                        <Label name={name} label={props.label} />
                        <HexColorPicker
                            color={value}
                            onChange={onValueChanged}
                        />
                        <div
                            className='mt-4 rounded'
                            style={{
                            background: value,
                            width: '200px',
                            height: '64px'
                        }}>

                        </div>
                    </div>
                )
            }}
        />
    )

}

export default SelectColor;