import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OptionsOrGroups } from "react-select";
import { customerSearchStore } from "stores/CustomerSearchStore";
import { customersStore } from "stores/CustomersStore";
import { CustomerListInterface } from "types/CustomerListInterface";
import SelectAsync from "../SelectAsync";
import CreateCustomerModal from "./CreateCustomerModal";
import CustomerDetailsModal from "./CustomerDetailsModal";

interface Props {
    label: string,
    name: string,
    placeholder?: string,
}

const SelectCustomer = observer((props: Props) => {
    const { setValue, watch } = useFormContext();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [newCustomerName, setNewCustomerName] = useState('');
    const [customerId, setCustomerId] = useState<null | string>();

    useEffect(() => {
        customersStore.getCustomers();

        return () => {
            customersStore.resetStore();
        }
    }, [])

    const value = watch([props.name]);
    useEffect(() => {
        if (value[0]?.id) {
            setCustomerId(value[0].id);
        }
        else {
            setCustomerId(null);
        }
    }, [value])

    const onShowCreateModal = (customerName: string) => {
        setNewCustomerName(customerName);
        setShowCreateModal(true);
    }

    const afterSubmitNewCustomer = (customer: CustomerListInterface) => {
        setValue(props.name, {
            id: customer.id,
            name: customer.name
        })

        onCloseNewCustomer();
    }

    const onCloseNewCustomer = () => {
        setNewCustomerName('');
        setShowCreateModal(false);
    }

    const onCloseEditModal = (customer: CustomerListInterface) => {
        setValue(props.name, {
            id: customer.id,
            name: customer.name
        })

        setShowEditModal(false);
    }

    const loadOptions = async (value: string, callback: (options: OptionsOrGroups<any, any>) => void) => {
        if (!value) {
            customerSearchStore.resetStore();
            callback([]);
        }

        if (value.length > 2) {
            const results = await customerSearchStore.getCustomersByQuery(value);
            if (results) {
                callback(results.map((entity) => ({
                    id: entity.id,
                    name: entity.name
                })))
            }
        }
    }

    return (
        <>
            <SelectAsync
                name={props.name}
                label={
                    <span>
                        {props.label}
                        {customerId
                            ?
                            <button
                                onClick={() => setShowEditModal(true)}
                                type='button'
                                className='underline ml-1'>
                                [vis detaljer]
                            </button>
                            : undefined}
                    </span>}
                loadOptions={loadOptions}
                placeholder={props.placeholder}
                onCreate={(value) => onShowCreateModal(value)}
            />
            {
                showCreateModal &&
                <CreateCustomerModal
                    onCancel={onCloseNewCustomer}
                    afterSubmit={afterSubmitNewCustomer}
                    newCustomerName={newCustomerName}
                />
            }
            {
                showEditModal &&
                <CustomerDetailsModal customerId={customerId || null} onClose={onCloseEditModal} />
            }
        </>
    )

})

export default SelectCustomer;