import { observer } from "mobx-react-lite";
import { baseRequestStore } from "stores/BaseRequestStore";
import './style.scss';

const LoadingIndicator = observer(() => {
    const requestCount = baseRequestStore.requestCount;
    if (requestCount < 1) return null;

    return (
        <div className="spinner-box">
            <div className="configure-border-1">
                <div className="configure-core"></div>
            </div>
            <div className="configure-border-2">
                <div className="configure-core"></div>
            </div>
        </div>
    )
})

export default LoadingIndicator;